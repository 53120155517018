import React, { Component } from 'react';
import FooterComponent from './footer';
import HeaderComponent from './header';
import Logo404 from './logo404';
import { NavLink } from 'react-router-dom';
/** Logo 404 */
/** Estilos componente */
import '../Styles/brandsStyles.css'



class NotFound extends Component {

    render() {
        let colorRandom = this.props.colorRandom;
        let colorTextRandom = this.props.colorTextRandom;
        return (
            <>
                <div style={{ background: colorRandom, color:'#ffff',minHeight : '7vh' }}>
                    <HeaderComponent/>
                </div>
                <div className="containerNotFound container fuenteLight mb-5" style={{minHeight : '70vh'}}>
                    <div className='w-100 mb-1 mt-5'>
                        <Logo404 colorRandom={colorRandom} colorTextRandom={colorTextRandom}  />
                    </div>
                    <div className=" row text-center">
                        <NavLink to="/" className="navlink text-decoration-none text-reset col-sm-12 col-md-6 col-lg-3">
                            <i style={{color : colorRandom}} >HOME</i>
                        </NavLink>
                        <NavLink to="/company" className="navlink text-decoration-none text-reset col-sm-12 col-md-6 col-lg-3">
                            <i style={{color : colorRandom}} >COMPANY</i>
                        </NavLink>
                        <NavLink to="/work" className="navlink text-decoration-none text-reset col-sm-12 col-md-6 col-lg-3">
                            <i style={{color : colorRandom}} >WORK</i>
                        </NavLink>
                        <NavLink to="/contact" className="navlink text-decoration-none text-reset col-sm-12 col-md-6 col-lg-3">
                            <i style={{color : colorRandom}} >CONTACT</i>
                        </NavLink>
                    </div>
                </div>
                <div style={{ background: colorRandom, minHeight : '23vh', bottom: 0}}>
                    <FooterComponent/>
                </div>
            </>
        );
    }
};

export default NotFound;
