import React from "react";
import Arcqos from "../src/images/PORTADAS MOSAICO/portada10.png"
import Medmar from "../src/images/medmarportada.png"
import Salinas from "../src/images/PORTADAS MOSAICO/portada3.png"
import Maravillas from "../src/images/PORTADAS MOSAICO/portada15.png"
import Ecoblocks from "./images/ecoportada.jpg"
import SGC from "../src/images/sgcportada(1).png"
import Clay from "./images/PORTADAS MOSAICO/portada6.png"
import Rakenus from "./images/PORTADAS MOSAICO/portada2.png"
import BoiseM from "./images/museum/mosaico.jpg"
import ColorTek from  "./images/PORTADAS MOSAICO/portada12.png"
import CactusBar from "./images/PORTADAS MOSAICO/portada7.png"
import Kovas from "./images/PORTADAS MOSAICO/portada14.png"

import arc1 from "./images/ARCQOS/1.png"
import arc2 from "./images/ARCQOS/2.png"
import arc3 from "./images/ARCQOS/3.png"
import arc4 from "./images/ARCQOS/4.gif"
import arc5 from "./images/ARCQOS/5.gif"
import arc6 from "./images/ARCQOS/6.gif"
import arc7 from "./images/ARCQOS/7.png"
import arc8 from "./images/ARCQOS/8.png"
import arc9 from "./images/ARCQOS/9.png"

import block1 from "./images/ecoblocks 2/1.png"
import block2 from "./images/ecoblocks 2/2.png"
import block3 from "./images/ecoblocks 2/3.png"
import block4 from "./images/ecoblocks 2/4.jpg"
import block5 from "./images/ecoblocks 2/5.jpg"
import block6 from "./images/ecoblocks 2/6.jpg"
import block7 from "./images/ecoblocks 2/7.jpg"
import block8 from "./images/ecoblocks 2/8.jpg"
import block9 from "./images/ecoblocks 2/9.jpg"

import sal1 from "./images/LAS SALINAS/salinas1.jpg"
import sal2 from "./images/LAS SALINAS/salinas2.jpg"
import sal3 from "./images/LAS SALINAS/salinas3.png"
import sal4 from "./images/LAS SALINAS/salinas4.png"
import sal5 from "./images/LAS SALINAS/salinas5.png"
import sal6 from "./images/LAS SALINAS/salinas6.jpg"
import sal7 from "./images/LAS SALINAS/salinas7.png"
import sal8 from "./images/LAS SALINAS/salinas8.jpg"
import sal9 from "./images/LAS SALINAS/salinas9.png"

import med5 from "./images/MEDMAR/1.png"
import med7 from "./images/MEDMAR/medmar7.jpg"
import med8 from "./images/MEDMAR/medmar8.jpg"
import med9 from "./images/MEDMAR/medmar9.jpg"
import med14 from "./images/MEDMAR/medmar14.jpg"
import med16 from "./images/MEDMAR/medmar16.jpg"
import med11 from "./images/MEDMAR/7.png"
import med12 from "./images/MEDMAR/8.png"
import med17 from "./images/MEDMAR/9.png"

import mar1 from "./images/maravillas/1.png"
import mar2 from "./images/maravillas/2.jpg"
import mar3 from "./images/maravillas/3.jpg"
import mar4 from "./images/maravillas/4.png"
import mar5 from "./images/maravillas/5.jpg"
import mar6 from "./images/maravillas/6.jpg"
import mar7 from "./images/maravillas/7.png"
import mar8 from "./images/maravillas/8.jpg"
import mar9 from "./images/maravillas/9.png"

import sgc1 from "./images/sgcIm/sgc1.jpg"
import sgc2 from "./images/sgcIm/sgc2.jpg"
import sgc3 from "./images/sgcIm/sgc3.jpg"
import sgc4 from "./images/sgcIm/sgc4.jpg"
import sgc5 from "./images/sgcIm/sgc5.jpg"
import sgc6 from "./images/sgcIm/sgc6.png"
import sgc7 from "./images/sgcIm/sgc7.jpg"
import sgc8 from "./images/sgcIm/sgc8.jpg"
import sgc9 from "./images/sgcIm/sgc9.png"

import clay1 from "./images/CLAYTOR/1.png"
import clay2 from "./images/CLAYTOR/2.png"
import clay3 from "./images/CLAYTOR/3.png"
import clay4 from "./images/CLAYTOR/4.png"
import clay5 from "./images/CLAYTOR/5.png"
import clay6 from "./images/CLAYTOR/6.png"
import clay7 from "./images/CLAYTOR/7.png"
import clay8 from "./images/CLAYTOR/8.png"
import clay9 from "./images/CLAYTOR/9.png"

import rake1 from "./images/RAKENNUS/rakennus1.png"
import rake3 from "./images/RAKENNUS/rakennus3.png"
import rake4 from "./images/RAKENNUS/rakennus4.gif"
import rake5 from "./images/RAKENNUS/rakennus5.png"
import rake6 from "./images/RAKENNUS/rakennus6.png"
import rake7 from "./images/RAKENNUS/rakennus7.png"
import rake8 from "./images/RAKENNUS/rakennus8.png"
import rake9 from "./images/RAKENNUS/rakennus9.png"
import rake10 from "./images/RAKENNUS/rakennus10.png"

import boise1 from "./images/museum/1.jpg"
import boise2 from "./images/museum/2.jpg"
import boise3 from "./images/museum/3.jpg"
import boise4 from "./images/museum/4.jpg"
import boise5 from "./images/museum/5.jpg"
import boise6 from "./images/museum/6.jpg"
import boise7 from "./images/museum/7.jpg"
import boise8 from "./images/museum/8.jpg"
import boise9 from "./images/museum/9.jpg"

import color1 from "./images/COLORTEK/1.png"
import color2 from "./images/COLORTEK/2.png"
import color3 from "./images/COLORTEK/3.png"
import color4 from "./images/COLORTEK/4.png"
import color5 from "./images/COLORTEK/5.png"
import color6 from "./images/COLORTEK/6.png"
import color7 from "./images/COLORTEK/7.png"
import color8 from "./images/COLORTEK/8.png"
import color9 from "./images/COLORTEK/9.png"

import cact1 from "./images/CACTUSBAR/1.png"
import cact2 from "./images/CACTUSBAR/2.png"
import cact3 from "./images/CACTUSBAR/3.png"
import cact4 from "./images/CACTUSBAR/4.jpg"
import cact5 from "./images/CACTUSBAR/5.png"
import cact6 from "./images/CACTUSBAR/6.jpg"
import cact7 from "./images/CACTUSBAR/7.jpg"
import cact8 from "./images/CACTUSBAR/8.jpg"
import cact9 from "./images/CACTUSBAR/9.jpg"

import kovas1 from "./images/KOVAS/kovas1.png"
import kovas2 from "./images/KOVAS/kovas2.png"
import kovas3 from "./images/KOVAS/kovas3.png"
import kovas4 from "./images/KOVAS/kovas4.png"
import kovas5 from "./images/KOVAS/kovas5.png"
import kovas6 from "./images/KOVAS/kovas6.png"
import kovas7 from "./images/KOVAS/kovas-new1.png"
import kovas8 from "./images/KOVAS/kovas8.png"
import kovas9 from "./images/KOVAS/kovas10.png"

let colors = ['#3C1252', '#6F4163', '#9478D3', '#BFAFD5', '#E4B22F']
let colorRandom = colors[Math.floor(Math.random() * colors.length)];
let colorsText = ['#FFFFFF', '#3C1252', '#9478D3', '#BFAFD5', '#E4B22F']
let colorTextRandom = colorsText[Math.floor(Math.random() * colorsText.length)];
while (colorTextRandom == colorRandom || (colorRandom == '#E4B22F' && colorTextRandom == '#BFAFD5')) {
    colorTextRandom = colorsText[Math.floor(Math.random() * colorsText.length)];
}
export const Colors = {
    
    colorTextRandom,
    colorRandom
}

export const CardsArray = [
    
        {
            image: Arcqos,
            tile: "Arcqos Safaris",
            link: "Arcqos",
            text1:"Arcqos",
            text2:" Safaris",
            carouselText: ["BRAND DEVELOPMENT", "WEB DEVELOPMENT"],
            textIndustry:["Safaris",
                "Travel",
                "Expeditions"],
            textServices:["Brand Identity","Web Development"],
            copy1:(
                <p>
                    Arcqos Safaris sought to redefine the standards of luxury adventure travel. They aimed to develop a brand identity that would embody their dedication to providing only the highest quality experiences. To effectively convey their commitment to excellence and appeal to discerning travelers, Arcqos Safaris partnered up with our expertise in luxury branding and travel industry marketing strategies.
                </p>
                ), 
            copy2:(
                <p>
                    First, we conducted a comprehensive market research to understand the preferences, desires, and aspirations of luxury adventure travelers. This research involved analyzing target demographics, studying market trends, and identifying key differentiators for Arcqos Safaris' high-quality offering. <br /><br />Next, based on our research findings, we developed an excellence-focused brand strategy for Arcqos Safaris. This involved emphasizing their commitment to delivering extraordinary experiences, continuous improvement, and exceeding traveler expectations. <br /><br />Lastly, we created visual identity for Arcqos Safaris that captured the essence of luxury, adventure, and exclusivity. This included selecting a refined color palette, and in-corporating elements that conveyed a sense of prestige and excitement associated with the company's exceptional offering. <br /><br /> Our team crafted compelling messaging that conveyed Arcqos Safaris' dedication to delivering unparalleled adventures. The messaging emphasized the company's commitment to continuous improvement, innovation, and personalized attention to detail. These messages were integrated into various marketing materials, website content, brochures, and promotional campaigns to captivate and inspire potential travelers.
                </p>
                
            ),
            
            copy3:(
                <p>
                    Our strategic branding initiatives resulted in heightened appeal among discerning travelers, differentiation from competitors, and an enhanced reputation as a leader in the luxury adventure travel industry. With a focus on continuous improvement and the relentless pursuit of perfection, Arcqos Safaris will continue to redefine the boundaries of exceptional travel expe-riences and set new standards of excellence in the industry.
                </p>
            ),
            images:[arc1,arc2,arc3,arc4,arc5,arc6,arc7,arc8,arc9],
            video1: require('./images/ARCQOS/videosesionnocturna.mp4'),
            results:(
                <p>
                    <b><li>Differentiation and Market Leadership:</li></b>
                    <ul>The new brand identity successfully differentiated Arcqos Safaris from competitors in the  luxury  adventure  travel  market.  The  focus  on  surpassing  traveler  expectations, continuous enhancement of offerings, and a dedication to excellence positioned the company  as  a  leader  in  the  industry,  offering  unrivaled  journeys  and  experiences.</ul>
                    <b><li>Target Market Attraction:</li></b>
                    <ul>The  website  of  Arcqos  Safaris  stands  out  from  their  competitors.  Through  intuitive  navigation, captivating visuals, and engaging content, the website creates an immersive online experience that captivates potential travelers and reinforces Arcqos Safaris' position as a leader in luxury adventure travel.</ul>
                    <b><li>Continuous Enhancement of Offerings: </li></b>
                    <ul>The  brand  identity  development  aligned  with  Arcqos ́  focus  on  never  settling  for  "good  enough".  This  commitment  to  improvement  and  staying  ahead  of  industry trends allowed Arcqos Safaris to maintain a competitive edge and attract travelers who sought the pinnacle of luxury adventure</ul>
                </p>
            )

        },
    
        {
            image: Medmar,
            tile: "Medmar",
            link: "Medmar",
            text1:"Med",
            text2:"Mar",
            carouselText: ["BRAND DEVELOPMENT", "WEB DEVELOPMENT","MEDIA MANAGMENT"],
            textIndustry:["Healthcare",
                "Medical Attention",
                "Emergencies"],
            textServices:["Brand Identity","Web Development"],
            copy1:(
                <p>
                    Medmar, a leading healthcare clinic in Mexico that specializes in providing patient-centered medical services for interna-tional travelers. With a mission to provide personalized care and reliable medical services, Medmar aimed to establish itself as a trusted clinic catering to the diverse needs of international patients. <br /><br /> The goal was to create a brand that would resonate with international travelers, differentiate Medmar from competitors, and position the clinic as a trusted and patient-focused healthcare provider in Mexico.
                </p>
            ),
            copy2:(
                <p>
                    First, we conducted extensive research to understand the unique healthcare needs, preferences, and concerns of international travelers. This research involved gathe-ring patient insights, analyzing their expectations, and identifying pain points in their healthcare journey. <br /><br /> Next, based on the patient research findings, we developed a patient-centric brand strategy for Medmar. This involved defining the clinic's commitment to personalized care, convenience, accessibility, and exceptional patient experiences throughout the healthcare journey. <br /><br /> Lastly, we designed a visually appealing and patient-centric brand identity for Medmar. This included creating a compassionate and trustworthy logo, selecting colors and typography that evoke a sense of care and empathy, and ensuring that the visual elements aligned with the patient-focused values of the clinic. <br /><br /> We crafted a compelling messaging that resonated with international travelers, emphasizing Medmar's patient-centric approach, personalized care, and commitment to meeting patient needs. This messaging was incorporated into various marketing materials, website content, patient communications, and social media campaigns.
                </p>
            ),
            copy3:(
                <p>
                    This patient-centric approach was pivotal in developing a strong brand identity that prioritizes the right message for healthcare clinics catering to international travelers, like Medmar. We were able to understand the significance of patient-centric care so that Medmar successfully crafted a brand identity that aligned with its commitment to personalized care.
                </p>
            ),
            images:[med5,med7,med8,med9,med14,med16,med11,med12,med17],
            results:(
                <p>
                    <b><li>Patient-Centered Experience: </li></b>
                    <ul>Medmar successfully developed a patient-centric culture and experience, aligning its brand identity with the unique needs and expectations of international travelers. The patient-focused branding efforts ensured that every interaction and touchpoint reflected the clinic's commitment to personalized care and exceptional experiences.</ul>
                    <b><li>Enhanced Patient Trust and Satisfaction:</li></b>
                    <ul>The patient-centric brand identity instilled trust and confidence in international travelers seeking healthcare services in Mexico. Medmar's emphasis on patient needs and expectations led to improved patient satisfaction, resulting in positive word-of-mouth referrals and recurring visits.</ul>
                    <b><li>Patient Engagement and Empowerment:</li></b>
                    <ul>Medmar effectively engaged and empowered patients through clear and transparent communication, educational resources, and personalized support. The patient-cen-tric brand messaging fostered stronger patient-clinic relationships, enabling patients to make informed decisions about their healthcare while feeling supported throughout their journey.</ul>
                </p>
            )
        },
    
        {
            image: Salinas,
            tile: "Las Salinas",
            link: "Las-Salinas",
            text1:"Las",
            text2:" Salinas",
            carouselText: ["BRAND DEVELOPMENT"],
            textIndustry:["Real Estate",
                "Development",
                "Residential", "Commercial"],
            textServices:["Brand Identity"],
            copy1: (
                <p>
                    Las Salinas recognized the growing demand for sustainable and environmentally friendly real estate options in Yucatan, Mexico. In response, the company embarked on a mission to develop eco-friendly properties that harmonized with the natu-ral surroundings while providing a high-quality living experience. To effectively convey their unique approach and appeal to environmentally conscious buyers, Las Salinas partnered up with us; trusting our expertise in sustainable branding and real estate marketing strategies.
                </p>
            ) ,
            copy2:(
                <p>
                    First, we conducted extensive market research to understand the preferences, needs, and expectations of environmentally conscious buyers in the Yucatan region. This research involved analyzing the target audience, studying market trends, and identifying key differentiators for Las Salinas' eco-friendly properties. <br /><br />Next, based on our research findings, we developed a comprehensive sustainable brand strategy for Las Salinas. This encompassed defining the company's vision, mis-sion, and core values, emphasizing its commitment to environmental sustainability, responsible construction practices, and the preservation of natural resources. <br /><br /> Lastly, we created a nature-inspired and visually captivating brand identity for Las Salinas. This included designing a unique logo that symbolized the fusion of nature and architecture, selecting an pink color palette, and incorporating elements inspired by the local fiora and fauna to reinforce the company's eco-friendly focus. <br /><br /> We showcased Las Salinas' sustainable practices, innovative design concepts, and integration with the natural environment. These narratives were integrated into va-rious marketing materials, including website content, brochures, and promotional videos, to engage and inspire potential buyers.
                </p>
            ),
          
            copy3:(
                <p>
                    Our strategic branding initiatives yielded positive outcomes for the company. As Las Salinas continues to uphold its eco-friendly values and deliver exceptional living ex-periences in harmony with nature, its strong brand identity will play a pivotal role in sustaining its growth, fostering buyer trust, and positioning the company as a key player in Yucatan's eco-friendly real estate landscape.
                </p>
            ),
            images:[sal1,sal2,sal3,sal4,sal5,sal6,sal7,sal8,sal9], 
            video1:require('./images/LAS SALINAS/salinasvideo.mp4'),
            video2:require('./images/LAS SALINAS/salinasvideo1.mp4'),
            results:(
                <p>
                    <b><li>Increased Appeal to Eco-Conscious Buyers:</li></b>
                    <ul>Las Salinas experienced heightened appeal among environmentally conscious buyers seeking sustainable real estate options. The brand identity effectively communicated the company's commitment to eco-friend practices, resonating with buyerswho value responsible development and a harmonious integration with nature</ul>
                    <b><li>Differentiation and Competitive Advantage:</li></b>
                    <ul>The  new  brand  identity  successfully  differentiated  Las  Salinas  from  traditional  real  estate developers. The focus on eco-friendly practices and sustainable development positioned the company as a leader in the market, giving it a distinct competitive advantage in Yucatan, Mexico.</ul>
                    <b><li>Enhanced Reputation and Trust:</li></b>
                    <ul>The brand identity development efforts fostered a strong reputation and trust among potential  buyers.  Las  Salinas'  dedication  to  sustainability,  transparent  communication,  and  responsible  construction  practices  established  the  company  as  a  trusted  provider of eco-friendly real estate solutions in the region</ul>
                </p>
            )
        },

        {
            image: Maravillas,
            tile: "Maravillas del Mayab",
            link: "Maravillas-del-Mayab",
            text1:"Maravillas",
            text2:" del Mayab",
            carouselText: ["BRAND DEVELOPMENT"],
            textIndustry:["Real Estate",
                "Development",
                "Residential", "Commercial"],
            textServices:["Brand Identity", "Media Management"],
            copy1:(
                <p>
                    Maravillas del Mayab is a captivating development nestled just 25 minutes away from the vibrant city of Mérida, renowned as one of the safest cities in Latin America. As a gateway to the wonders of the Yucatán Peninsula, Maravillas del Mayab beckons adventurers and dreamers alike to embrace a unique lifestyle filled with natural beauty, tranquility, and endless possibilities <br /><br /> The company needed to establish a distinctive brand identity, develop a captivating online presence, manage media chan-nels strategically, and launch targeted advertising campaigns to reach potential residents and investors.
                </p>
            ),

            copy2:(
                <p>
                    First, we conducted extensive research analyzing the real estate landscape in Yucatán, identifying key trends, and understanding the preferences and needs of the target audience. The research helped developing a tailored approach that would set Maravillas del Mayab apart from its competitors. By studying factors such as demand, pricing trends, and consumer preferences;This analysis provided a deep understanding of the target audience's expectations and allowed us to position Maravillas del Mayab effectively. <br /><br /> Next, based on our findings, we identified the development's unique selling proposition, highlighting its exceptional location, proximity to key attractions, and the allure of Yucatán's natural beauty. This helped understanding the opportunities for differentiation and determine the best strategies to position Maravillas del Mayab as a standout development. <br /><br /> Lastly, we worked on designing a compelling brand identity that showcased the development's essence, values, and aspirations. This involved crafting a memorable logo, selecting a harmonious color palette, and designing captivating visual elements. Adittionally, we managed Maravillas del Mayab's social media channels, curating captivating content that showcased the development's features, lifestyle, and nearby attractions.
                </p>
            ),

            copy3:(
                <p>
                    Our  strategic approach to brand development, online presence, media management, and advertising proved instrumental in positioning Maravillas del Mayab as a standout choice for residents and investors seeking a unique, sustainable, and captivating real estate opportunity in the region.
                </p>
            ),
            images:[mar1,mar2,mar3,mar4,mar5,mar6,mar7,mar8,mar9],
            video1: require('../src/images/MARAVILLAS DEL MAYAB/maravillas-vid1.mp4'),
            video2: require('../src/images/MARAVILLAS DEL MAYAB/maravillas-vid.mp4'),
            results:(
                <p>
                    <b><li>Increased Brand Awareness:</li></b>
                    <ul>Through targeted advertising campaigns and strategic media management, Maravillas  del  Mayab  experienced  a  significant  boost  in  brand  visibility.  The  development became widely recognized and associated with its unique selling points, attracting attention from potential residents and investors.</ul>
                    <b><li>Differentiation from Competitors: </li></b>
                    <ul>The thorough market and competitive research enabled Maravillas del Mayab to differentiate itself effectively from competitors. The development's unique location, sustainable  features,  and  the  allure  of  Yucatán's  natural  beauty  became  key  selling points that set it apart in the market.</ul>
                    <b><li>Positive Audience Engagement: </li></b>
                    <ul>Through  our  management  of  social  media  channels  and  the  creation  of  engaging content, Maravillas del Mayab fostered a strong sense of community and engaged with its target audience. This led to positive interactions, increased brand loyalty, and word-of-mouth referrals, further amplifying the development's reach and reputation.</ul>
                </p>
            )
            
        },

        {
            image: Ecoblocks,
            tile: "Ecoblocks del Sur",
            link: "Ecoblocks-del-Sur",
            text1: "Ecoblocks",
            text2:" del Sur",
            carouselText: ["BRAND DEVELOPMENT"],
            textIndustry:["Construction",
                "Sustainable Construction",
                "Eco-friendly"],
            textServices:["Brand Identity"],
            copy1:(
                <p>
                    Ecoblocks, an innovative company committed to sustainable construction practices, sought to establish itself as a market leader in the industry. With a strong focus on producing environmentally friendly construction materials, Ecoblocks aimed to create a distinctive brand identity that would convey its values, uniqueness, and commitment to sustainable development.<br /><br />The aim was to create a brand that would resonate with target customers, differentiate Ecoblocks from competitors, and position the company as a trusted provider of sustainable construction solutions.
                </p>
            ),
            copy2:(
                <p>
                    First, we conducted in-depth market research and analysis to gain insights into the target audience, competitors, and industry trends. We identified the unique selling 
                    points of Ecoblocks' sustainable blocks and analyzed customer preferences and perceptions.<br /><br /> Next, based on the research findings, we developed a comprehensive brand strategy for Ecoblocks. This included defining the brand's vision, mission, values, and posi
                    tioning within the sustainable construction industry.<br /><br /> . Lastly, we created a visually appealing and memorable brand identity for Ecoblocks. This encompassed designing a distinctive logo, selecting an appropriate color palet
                    te, and creating a brand guidelines manual that reflected the company's commitment to sustainability.<br /><br /> We crafted a compelling messaging that effectively conveyed Ecoblocks' unique value proposition and resonated with the target audience. This messaging was incorpo
                    rated into various marketing materials, such as brochures, and social media campaigns
                </p>
            ),

            copy3: (
                <p>
                    By understanding their values and target audience, we were able to create a brand 
                    identity that aligned with Ecobloks ́ sustainability-focused offerings. The successful 
                    implementation of the brand identity resulted in increased brand awareness, en
                    hanced customer perception, and differentiation from competitors, ultimately posi
                    tioning Ecoblocks as a leading provider of sustainable blocks for construction.
                </p>
            ),
            images:[block1,block2,block3,block4,block5,block6,block7,block8,block9],
            results:(
                <p>
                    <b><li>Increased Brand Awareness:</li></b>
                    <ul>Ecoblocks  experienced  a  substantial  increase  in  brand  recognition  and  awareness within the target market. The visually appealing brand identity and compelling messaging helped Ecoblocks stand out from competitors and attract attention from potential customers.</ul>
                    <b><li>Enhanced Customer Perception:</li></b>
                    <ul>The new brand identity effectively communicated Ecoblocks' commitment to sustainability,leading to an improved customer perception of the company. Customers associated Ecoblocks with high-quality, eco-friendly construction solutions, further boosting its reputation.</ul>
                    <b><li>Differentiation from Competitors:</li></b>
                    <ul>Ecoblocks successfully differentiated itself from other construction material providers by  emphasizing  its  sustainable  practices.  The  brand  identity  development  efforts highlighted Ecoblocks' unique selling points and established it as a leader in the sustainable construction space.</ul>
                </p>
            )
        },

        {
            image: SGC,
            tile: "SGC INC. Consulting ",
            link: "SGC-INC-Consulting",
            text1:"SGC INC.",
            text2:" CONSULTING",
            carouselText: ["BRAND DEVELOPMENT"],
            textIndustry:["Construction",
                "Manufacturing",
                "Consulting"],
            textServices:["Brand Identity"],
            copy1: (
                <p>
                    SCG Inc Consulting is a consulting group dedicated to providing expert services to the forest products industry. With a focus on  infrastructure  management  and  capital  construction,  SCG  Inc  Consulting  aims  to  support  companies  in  their  decision-making process by offering valuable insights, guidance, and mentorship. <br /><br /> We collaborated closely with SCG Inc Consulting to develop a strong and compelling brand that aligned with its vision and services.
                </p>
            ),
            copy2:(
                <p>
                    First, SCG Inc Consulting defined its mission, which is to provide snapshots of opportunities and guidance to forest products companies for infrastructure and capital planning. The group's value proposition was established, emphasizing its expertise in infrastructure management, capital construction, and mentoring emerging project managers and engineers <br /><br /> Next, we identify the target audience within the forest products industry. Understanding the target audience helped us shape the messaging and positioning strategies. <br /><br /> Lastly, we crafted a distinct brand identity that reflected the group's expertise, professionalism, and commitment to client success. This included designing a memorable logo, selecting appropriate color schemes, and creating consistent visual elements to be used across various platforms.
                </p>
            ),
            copy3:(
                <p>
                    Through strategic branding initiatives, SCG Inc Consulting achieved enhanced brand recognition, improved professionalism, and differentiation from competitors. The consistent brand experience, coupled with a positive client perception, genera-ted trust, loyalty, and advocacy. The development of professional stationery further reinforced the group's credibility and attention to detail.
                </p>
            ),
            images: [sgc1,sgc2,sgc3,sgc4,sgc5,sgc6,sgc7,sgc8,sgc9],
            results:(
                <p>
                    <b><li>Enhanced Brand Recognition:</li></b>
                    <ul>Through strategic brand development and targeted marketing efforts, SCG Inc Consulting gained recognition and visibility within the industry. The group's expertise and unique  value  proposition  became  associated  with  its  brand,  distinguishing  it  from competitors</ul>
                    <b><li>Development of Stationery:</li></b>
                    <ul>As part of the brand development process, we included the design of business cards, letterheads, and envelopes that reflected the group's brand identity. The consistent and visually appealing stationery added a professional touch to communication and reinforced the credibility of SCG Inc Consulting in their interactions with clients and partners.</ul>
                    <b><li>Increased Trust and Credibility:</li></b>
                    <ul>A  well-developed  brand  instills  trust  and  credibility  among  clients.  The  strategic branding  of  SCG  Inc  Consulting  created  a  professional  image  that  resonated  with their target audience. As a result, clients perceived the group as a reliable and knowledgeable partner, leading to an increased willingness to engage and collaborate on infrastructure and capital planning projects.</ul>
                </p>
            )
        },

        {
            image: Clay,
            tile: "Claytor Coaching",
            link: "CLAYTOR-COACHING",
            text1:"CLAYTOR",
            text2:" COACHING",
            carouselText: ["BRAND DEVELOPMENT", "WEB DEVELOPMENT"],
            textIndustry:["Personal Coaching",
                "Mental health"],
            textServices:["Brand Identity","Web Development"],
            copy1:(
                <p>
                    Claytor Coaching is a coaching service founded by Dave, an experienced Leadership and Emotional Intelligence Coach 
                    dedicated to empowering individuals to unlock their full potential. With a personalized one-to-one coaching method that incorporates  strategic  intervention,  mental  fitness  practices,  and  various  tools,  Claytor  Coaching  helps  clients  define  and  
                    achieve their goals. <br/><br/>
                    This is how our branding and website development efforts helped establish a strong online presence and effectively convey 
                    the essence of Claytor Coaching's coaching approach.
                </p>
            ),
            copy2:(
                <p>
                    First, we conducted an extensive market market research to understand the target audience. We also wanted to emphisize how he helps individuals discover their motivations, overcome obstacles, and achieve desired results through a confidential and mutually respectful coaching relationship. <br/><br/>Next, we develop a clear and impactful brand messaging. This messaging emphasized the values of trust, partnership, and integration that underpin Claytor Coaching's 
                    coaching method. The messaging communicated the commitment to active listening, empowering questions, and collaborative goal-setting for real and lasting transformation. <br/><br/> Lastly, we created a visually appealing and consistent brand identity to reflect the essence of Claytor Coaching. This included designing a professional logo that represented trust, partnership, and growth. A complementary color palette and typography were selected to evoke a sense of professionalism, trustworthiness, and personal. <br/><br/> Aditionally, the website development for Claytor Coaching focused on creating an engaging and user-friendly online platform to effectively showcase the coaching services and attract potential clients growth.
                </p>
            ),

            copy3:(
                <p>
                    Our strategic branding and website development efforts for Claytor Coaching, successfully positioned his services as a trusted and transformative resource for individuals seeking personal and professional growth. The cohesive brand identity, clear service descriptions, and user-friendly website collectively contributed to increased brand awareness, and enhanced credibility.
                </p>
            ), 
            images:[clay1,clay2,clay3,clay4,clay5,clay6,clay7,clay8,clay9],
            results:(
                <p>
                    <b><li>Strong Brand Identity: </li></b>
                    <ul>Claytor Coaching established a strong brand identity that effectively communicated its values of trust, partnership, and integration. The cohesive branding elements, including the logo, color palette, and typography, contributed to brand recognition and differentiation in the coaching industry</ul>
                    <b><li>Increased Client Inquiries: </li></b>
                    <ul>The user-friendly and informative website, coupled with compelling brand messaging, attracted a significant increase in client inquiries. The clear service descriptions, testimonials,  and  case  studies  provided  potential  clients  with  a  comprehensive  understanding  of  the  coaching  services  and  instilled  confidence  in  the  effectiveness  of  Claytor Coaching's coaching approach.</ul>
                    <b><li>Strengthened Client Relationships: </li></b>
                    <ul>Our strategy for brand messaging and the personalized coaching method employed by Claytor Coaching fostered stronger client relationships. By establishing a foundation of trust and partnership, clients felt comfortable sharing their goals, challenges, and aspirations with Dave. The interactive nature of the coaching sessions and the integration of feedback and adjustments ensured lasting results and ongoing professional and personal growth for the clients.</ul>
                </p>
            )
        },
        {
            image: Rakenus,
            tile: "Rakennus",
            link: "Rakennus",
            text1: "Rake",
            text2:"nnus",
            textIndustry:["Real Estate",
                "Development",
                "Residential", "Commercial"],
            textServices:["Brand Identity", "Media Management","Web Development"],
            carouselText: ["BRAND DEVELOPMENT", "WEB DEVELOPMENT", "MEDIA MANAGMENT"],
            copy1:(
                <p>
                    Rakennus is a distinguished Mexican real estate group with a focus on the search, recovery, transformation, and specialized renovation of buildings in prime locations across Mexico City. They are dedicated to creating sustainable and innovative real estate solutions. The company's commitment to sustainable practices not only addresses the pressing environmental challenges but also presents new financial opportunities that make it an attractive choice for investors in Mexico. <br/><br/>
                    Rakennus sought our expertise to develop its brand identity, establish a compelling online presence through web development, manage media channels effectively, and run targeted advertising campaigns. Our applied strategies showcased Rakennus' expertise, sustainability focus, and financial prospects to potential investors in Mexico.
                </p>
            ),
            copy2:(
                <p>
                    First, we conducted extensive market research to understand the needs and preferences of potential investors in the real estate sector. This research provided valuable
                    insights into market trends, investor expectations, and the competitive landscape. <br/><br/>
                    Next, based on the market analysis, we defined its brand positioning as a real estate group with a deep understanding of societal needs and a commitment to sustainable practices. The messaging was crafted to communicate Rakennus' expertise, passion, and the financial opportunities it offered to investors in Mexico. <br/><br/>
                    Lastly, we worked on designing a compelling visual identity for Rakennus that reflected its professionalism, innovation, and sustainability focus. This included logo
                    design, color palette selection, typography, and other visual elements that conveyed the brand's essence. <br/><br/>
                    As for the website, it was designed to provide a seamless user experience, ensuring easy navigation, clear information presentation, and intuitive functionality. We prioritized showcasing Rakennus' projects, services, and sustainability initiatives. We created engaging and informative content for the website, including project descriptions, case studies, sustainability initiatives, and financial opportunities for investors.
                </p>
            ),
            copy3:(
                <p>
                    The refined brand identity and web presence played a crucial role in positioning
                    Rakennus as a reputable and trusted player in the market. The engaging content,
                    seamless user experience, and optimized mobile responsiveness of the website contributed to a positive impression among visitors encouraging them to explore
                    Rakennus' projects and investment opportunities further.
                </p>
            ), 
            images:[rake1,rake4,rake3,rake10,rake5,rake6,rake7,rake8,rake9],
            results: (
                <p>
                    <b><li>
                    Strong Brand Identity: 
                    </li></b>
                    <ul>Rakennus successfully established a compelling brand identity that showcased its exertise,  passion, and sustainability focus. This resonated with potential investors seeking real estate opportunities aligned with societal needs and sustainable practices.</ul>
                    <b><li>Engaging Website Presence: </li></b>
                    <ul>The user-friendly and visually appealing website created for Rakennus served as a powerful tool for showcasing its projects, services, and values. The informative and 
                        engaging content, coupled with a seamless user experience, captured the attention of 
                        potential  investors  and  provided  them  with  the  necessary  information  to  make  informed decision.</ul>
                    <b><li>
                        Effective Advertising Campaigns:
                    </li></b>
                    <ul>
                    Our targeted advertising campaigns generated valuable leads and increased trafficto Rakennus' website. By reaching the right audience at the right time, these campaigns maximized the chances of attracting potential investors interested in Rakennus' sustainable real estate offerings.
                    </ul>
                </p>
            )
        },
        {
            image: BoiseM,
            tile: "BOISE SELFIE MUSEUM",
            link: "BOISE-SELFIE-MUSEUM",
            text1: "BOISE SELFIE",
            text2: "MUSEUM",
            carouselText: ["BRAND DEVELOPMENT", "WEB DEVELOPMENT", "MEDIA MANAGMENT"],
            textIndustry:["Museum",
                "Tourism",
                "Entretainment"],
            textServices:["Brand Identity", "Media Management","Web Development"],
            copy1: (
                <p>
                    The Boise Selfie Museum started as one of the most Instagramable places in Idaho, where captivating walls and changing art installations provide the perfect backdrop for your photos and videos. The museum was thoughtfully designed to trans-
                    port you to an entirely new level of visual storytelling. With 16 different art installments that were updated seasonally, there
                    was always something new and exciting to explore and inspire your creativity. <br /><br /> For this project, we focused on the branding, media management, and web development strategies to establish the museums´ presence, engage its target audience, and drive foot traffic.
                </p>
                
            ),
            copy2:(
                <p>
                    First, we conducted an extensive market research and competitor analysis, to identify its unique selling points and positioning within the industry. This also helped us to
                    define the target market and create a compelling message to position the museum among them. <br /><br />
                    Next, we develop a visually appealing brand identity, including a captivating logo and vibrant color palette that reflected the museum's playful and innovative atmosphere. We also crafted a compelling brand messaging that focused on the museum's mission to provide visitors with a unique and immersive experience for creating long-lasting memories and amazing pictures. <br /><br />
                    Lastly, we utilized social media platforms, particularly Instagram, to showcase its interactive art installations, engage with followers, and encourage user-generated content. Engaging captions, hashtags, and contests were used to promote visitor experiences and increase brand visibility.<br /><br />
                    Aditionally, the website we developed featured an intuitive navigation menu, vibrant imagery, and engaging content to showcase the museum's unique offerings and
                    drive online bookings.
                </p>
            ),
            copy3:(
                <p>
                    Our strategies resulted in increased brand awareness, growing social media following, enhanced visitor experience, increased footfall and ticket sales, positive
                    online reputation, and business collaborations for the Boise Selfie Museum, establishing it as a leading destination for capturing unforgettable moments and fostering
                    creativity in Idaho.
                </p>
            ),
            images:[boise1,boise2,boise3,boise4,boise5,boise6,boise7,boise8,boise9],
            results:(
                <p>
                    <b><li>Increased Brand Awareness:</li></b>
                    <ul>Through effective branding and media management, the museum gained widespread brand  recognition  and  became  synonymous  with  the  most  Instagram-able place in  Idaho. </ul>
                    <b><li>Growing Social Media Following:</li></b>
                    <ul>The  media  management  strategy  resulted  in  a  substantial  growth  of  BSM's  social media following. By consistently sharing visually appealing content, encouraging user-generated content, and leveraging influencer partnerships, the museum attracted a dedicated community of followers.</ul>
                    <b><li>Business Partnerships and Collaborations: </li></b>
                    <ul>The  successful  implementation  of  branding  and  media  management  strategies  attracted the attention of local businesses and brands. We secured strategic partnerships  and  collaborations  with  complementary  businesses,  such  as  local  photographers, influencers, and event organizers. These partnerships allowed the museum to expand its reach, tap into new markets, and host exclusive events, further enhancing its position as a prominent destination in the community.</ul>
                </p>
            )
        },
        {
            image: ColorTek,
            tile: "Colortek",
            link: "Colortek-Mexico",
            carouselText: ["BRAND DEVELOPMENT","MEDIA MANAGMENT"],
            text1:"Colortek",
            text2:" Mexico",
            textIndustry:["Decorative paints",
                "Interior Design",
                "Coating technologies"],
            textServices:["Brand Identity", "Media Management"],
            copy1:(
                <p>
                    Colortek, already a global leader in the decorative paints and floorings industry, recognized the potential of the Mexican 
                    market and aimed to establish a significant presence. With a desire to penetrate this new market and establish a strong 
                    brand presence, they sought our expertise to manage their social media presence, refine their messaging, and position their 
                    brand effectively to communicate innovation, and value proposition to architects, interior designers, and contractors.
                </p>
            ),
            copy2:(
                <p>
                    First, we conducted a comprehensive market research to gain insights into the preferences, needs, and expectations of the Mexican market in the decorative paints and 
                    floorings industry. This research involved analyzing the target audience, studying industry trends, and identifying key differentiators for Colortek's offerings.<br /><br />
                    Next, based on our research findings, we developed a comprehensive social media strategy for Colortek. This included selecting appropriate social media platforms, defining content themes and messaging, establishing a content calendar, and implementing effective engagement and growth strategies to reach and connect with the 
                    target audience.<br /><br />
                    Lastly, we worked closely with Colortek to refine their visual identity and messaging for the Mexican market. This involved creating a cohesive and visually appealing 
                    look and feel for Colortek's social media profiles, aligning them with the company's global brand identity while incorporating elements that resonated with the Mexican 
                    audience. Additionally, the messaging was fine-tuned to communicate Colortek's unique value proposition, innovation, and commitment to quality materials and coatings technologies.
                </p>
            ),
            copy3:(
                <p>
                    Through strategic social media management, refined visual identity, and targeted 
                    messaging, we were able to expand Colorteks ́ market reach, enhanced brand perception, and differentiated itself from competitors.<br />
                    By maintaining a strong social media presence, Colortek will further establish itself 
                    as a leading provider of innovative decorative paints and floorings in Mexico.
                </p>
            ),
            images:[color1,color2,color3,color4,color5,color7,color6,color8,color9],
            video1: require('../src/images/COLORTEK/colortek1.mp4'),
            video2: require('../src/images/COLORTEK/colortek2.mp4'),
            results:(
                <p>
                    <b><li>Expanded Market Reach:</li></b>
                    <ul>Through  effective  social  media  management  and  engagement  strategies,  Colortek experienced a substantial increase in its online visibility and market reach in Mexico. The carefully crafted content and targeted promotion reached architects, interior designers, and contractors, creating brand awareness and attracting potential customers</ul>
                    <b><li>Enhanced Brand Perception:</li></b>
                    <ul>The refined visual identity and messaging aligned Colortek's brand image with the expectations and preferences of the Mexican audience. This resulted in an enhanced brand perception as a provider of innovative and high-quality materials and coatings technologies, establishing trust and credibility in the market</ul>
                    <b><li>Market Differentiation:</li></b>
                    <ul>The refined brand positioning allowed Colortek to differentiate itself from competitors in  the  Mexican  market.  By  highlighting  its  innovative  materials,  coatings  technologies,  and  commitment  to  customer  satisfaction,  Colortek  stood  out  as  a  preferred choice for architects, interior designers, and contractors seeking high-quality decorative paints and floorings.</ul>
                </p>
            )
        },
        {
            image: CactusBar,
            tile: "Cactus Bar",
            link: "Cactus-Bar",
            text1:"Cactus",
            text2:" Bar",
            textIndustry:["Bar",
            "Drinking establishment"],
            textServices:["Brand Identity", "Media Management","Web Development"],
            carouselText: ["BRAND DEVELOPMENT", "WEB DEVELOPMENT", "MEDIA MANAGMENT"],
            copy1: (
                <p>
                    Cactus Bar, located in the vibrant city of Boise, faced challenges in standing out amidst a competitive bar scene. Acknowledging the need to attract a specific clientele seeking a more relaxed and inviting environment, Cactus Bar partnered up 
                    with us to help redefine its brand and create a unique identity. <br /><br />
                    The goal was to position Cactus Bar as the go-to place for friends to gather and enjoy a laid-back atmosphere, in contrast 
                    to the overpriced and loud options available in the market
                </p>
            ),
            copy2:(
                <p>
                    First, we conducted a comprehensive analysis of the local bar scene and identified the target audience seeking a casual and relaxed environment. By understanding 
                    their preferences and desires, the marketing firm developed strategies to differentiate Cactus Bar and create a distinct brand experience. <br /><br />
                    Next, based on the market analysis, we worked with Cactus Bar to redefine its brand positioning and messaging. This involved crafting a compelling narrative that emphasized the bar's casual and welcoming atmosphere, highlighting the quality of its beverages, and showcasing its dedication to creating a laid-back oasis for friends to 
                    gather. <br /><br />
                    Lastly, we developed a social media strategy to promote Cactus Bar's brand image and engage with the target audience. This involved creating enticing content, sharing 
                    updates on events and specials, and actively responding to customer inquiries and feedback.
                </p>
            ),
            copy3:(
                <p>
                    With its new brand positioning as a laid-back oasis, Cactus Bar has successfully 
                    carved out its niche in Boise's bar scene, providing a casual and inviting space for 
                    friends to gather, unwind, and create lasting memories.
                </p>
            ),
            images:[cact1,cact2,cact3,cact4,cact5,cact6,cact7,cact8,cact9],
            results:(
                <p>
                    <b><li>Targeted Audience Attraction:</li></b>
                    <ul>The refined brand positioning and messaging effectively attracted the target audience seeking a casual watering hole. By emphasizing the relaxed atmosphere, quality beverages, and the opportunity to connect with friends, Cactus Bar stood out as a preferred choice among its target customers.</ul>
                    <b><li>Differentiation and Unique Identity: </li></b>
                    <ul>Cactus Bar successfully differentiated itself from the overpriced and loud alternatives in the market. Its brand image as a casual and welcoming bar resonated with customers seeking a more laid-back experience, positioning Cactus Bar as a unique and appealing option.</ul>
                    <b><li>Engaged Community and Brand Advocacy:</li></b>
                    <ul>Through an engaging social media presence, Cactus Bar fostered a community of loyal customers who actively shared their experiences and recommendations. Positive word-of-mouth and online reviews further solidified Cactus Bar's reputation as a casual watering hole where friends could gather and have a great time.</ul>
                </p>
            )
        },

        {
            image: Kovas,
            tile: "Grupo Kovas",
            link: "Grupo-Kovas",
            text1:"GRUPO",
            text2:" KOVAS",
            carouselText: ["MEDIA MANAGMENT", "WEB DEVELOPMENT"],
            textIndustry:["Construction","Development","Real State"],
            textServices:["Media Management","Web Development"],
            copy1:(
                <p>
                    Grupo Kovas is a real estate company that offers investment projects in beautiful locations in the state of Yucatán. With a 
                    focus on providing semi-urbanized and fully urbanized lands with legal and financial certainty, Grupo Kovas aims to help 
                    clients build their short-term real estate portfolio. <br /><br />
                    We collaborated with them to enhance Grupo Kovas' online presence and effectively showcase their investment projects.
                </p>
            ),

            copy2:(
                <p>
                    First, we conducted an extensive market market research. Our media management team identified the key target audience interested in real estate investment opportu-
                    nities in Yucatán. Strategic online advertising campaigns were implemented to reach this specific audience across various digital platforms. This approach ensured that 
                    Grupo Kovas' investment projects were effectively promoted to potential investors. <br /><br />
                    Next, we develop compelling and informative content was created to engage the target audience and showcase the unique selling points of Grupo Kovas' investment 
                    projects. This included visually appealing images and videos highlighting the natural beauty of the locations and the development plans for each project. The content 
                    aimed to evoke a sense of exclusivity, financial stability, and the potential for short-term returns on investment. <br /><br />
                    Lastly, a visually captivating design was created to reflect the natural beauty of the locations and the vision of Grupo Kovas. The website incorporated high-quality 
                    images, videos, and interactive elements to provide visitors with an immersive experience and capture their attention. The color scheme and typography were carefully 
                    selected to align with the brand identity and evoke a sense of trust and exclusivity. <br /><br />
                    The website showcased the unique features and benefits of each project, highlighting the investment potential and the value proposition for potential investors. <br /><br />
                    
                </p>
            ),
            copy3: (
                <p>
                    Overall, the successful implementation of media management and web development strategies for Grupo Kovas resulted in improved online visibility, enhanced 
                    credibility and trust, and increased investor engagement. Through these efforts, 
                    Grupo Kovas was able to effectively showcase their investment projects, attract po
                    tential investors, and drive business growth in the competitive real estate market.
                </p>
            ),
            images:[kovas1,kovas2,kovas3,kovas4,kovas5,kovas6,kovas7,kovas8,kovas9],
            results:(
                <p>
                    <b><li>Increased Online Visibility:</li></b>
                    <ul>Through targeted advertising and a strong social media presence, Grupo Kovas experienced increased online visibility among the target audience. The investment projects gained exposure to potential investors, resulting in higher brand awareness and increased inquiries.</ul>
                    <b><li>Improved Investor Engagement:</li></b>
                    <ul>The engaging content, visually appealing website, and user-friendly navigation facilitated better investor engagement. Visitors spent more time exploring the investment projects and accessing the information they needed, leading to a higher level of interest and engagement with Grupo Kovas.</ul>
                    <b><li>Enhanced Credibility and Trust: </li></b>
                    <ul>The professional and visually appealing website design, combined with the informative  content  and  clear  presentation  of  investment  projects,  helped  establish  Grupo  Kovas as a trust worthy company among their target market. </ul>
                    <ul>The  strategic  implementation  of  media  management  and  web  development  for  Grupo Kovas proved to be a success in enhancing their online presence and effectively showcasing their investment projects.</ul>

                </p>
            )

        }


    
]
