import React, { Component, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom/dist';
import HeaderComponent from './header';
/** Estilos componente */
import '../Styles/homeStyles.css';
import { Colors } from '../variables';

class HomeComponent extends Component  {
    render() {

        let homeFlag = this.props?.homeFlag;
        return (
            <>
                <div className='mainContainer d-flex flex-column' style={{ background: this.props.colorRandom }}>
                    {/* svg del logo */}
                    <div className='logoDH d-flex align-items-center justify-content-center'>
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 500 500" style={{ "enableBackground": "new 0 0 500 500" }}>
                            <circle fill={this.props.colorRandom} cx="250" cy="250" r="223.15" />
                            <g>
                                <g>
                                    <path fill={this.props.colorTextRandom} d="M344,303.05l-34.87,0.77l-2.76-125.63c-0.01-0.46-0.39-0.82-0.85-0.81l-9.75,0.21
                c-0.46,0.01-0.82,0.39-0.81,0.85l2.76,125.63l-48.3,1.06c4.12-2.7,8.11-5.99,11.87-9.79c15.04-15.03,22.42-33.73,21.94-55.56
                c-0.5-22.62-8.97-41.43-25.18-55.92c-7.96-6.98-16.44-11.8-25.2-14.33c-8.73-2.52-19.16-3.66-31.02-3.4l-26.83,0.59
                c-0.46,0.01-0.82,0.39-0.81,0.85l1.41,64.07c0.02,0.79,0.68,1.42,1.47,1.41l8.25-0.18c0.8-0.02,1.43-0.68,1.42-1.48l-1.19-54.35
                l16.33-0.36c9.87-0.22,18.74,0.77,26.37,2.94c7.58,2.15,14.83,6.18,21.56,11.98c14.37,12.62,21.88,28.92,22.31,48.44
                c0.4,18.1-5.81,33.97-18.46,47.18c-11.62,12.01-28.09,18.34-48.94,18.8l-9.39,0.21l-6.94,0.15l-9.99,0.22
                c-0.63,0.01-1.13,0.54-1.12,1.17l0.19,8.57c0.01,0.46,0.39,0.82,0.85,0.81l119.65-2.63l0.71,32.32c0.01,0.46,0.39,0.82,0.85,0.81
                l9.75-0.21c0.46-0.01,0.82-0.39,0.81-0.85l-0.71-32.32l34.87-0.77c0.46-0.01,0.82-0.39,0.81-0.85l-0.19-8.77
                C344.84,303.41,344.46,303.04,344,303.05z"/>
                                    <g>
                                        <path fill={this.props.colorTextRandom} d="M105.22,278l-26.45,3.94c-0.21,0.03-0.41-0.11-0.44-0.32l-1.03-6.93l-4.35,0.65l2.85,19.12l4.35-0.64
                    l-1.02-6.84c-0.03-0.21,0.11-0.41,0.32-0.44l26.45-3.94L105.22,278z"/>
                                        <path fill={this.props.colorTextRandom} d="M103.57,264.44l0.15-4.64l-14.46-0.45c-0.21-0.01-0.38-0.18-0.37-0.4l0.42-13.45
                    c0.01-0.21,0.18-0.38,0.4-0.37l14.46,0.45l0.15-4.63l-30.88-0.96l-0.63-0.02l-0.15,4.64l11.89,0.37c0.21,0.01,0.38,0.18,0.37,0.4
                    l-0.42,13.45c-0.01,0.21-0.18,0.38-0.4,0.37l-11.89-0.37l-0.15,4.64l30.88,0.97L103.57,264.44z"/>
                                        <path fill={this.props.colorTextRandom} d="M105.74,232.14l3.69-16.55l-4.29-0.96l-2.6,11.65c-0.05,0.21-0.25,0.34-0.46,0.29l-9.61-2.15
                    c-0.21-0.05-0.34-0.25-0.29-0.46l2.52-11.29l-4.29-0.96l-2.52,11.3c-0.05,0.21-0.25,0.34-0.46,0.29l-6.79-1.52
                    c-0.21-0.05-0.34-0.25-0.29-0.46l2.6-11.65l-4.29-0.96l-3.61,16.18c-0.05,0.21,0.08,0.41,0.29,0.46l29.78,6.64L105.74,232.14z"/>
                                        <path fill={this.props.colorTextRandom} d="M118.12,191.89l3.06-5.45c1.31-2.33,2.2-4.48,2.65-6.38c0.46-1.93,0.44-4.08-0.06-6.37
                    c-0.99-4.47-3.59-7.91-7.73-10.23c-4.33-2.44-8.71-2.86-13.02-1.3c-2.13,0.79-3.92,1.91-5.32,3.33c-1.38,1.4-2.71,3.24-3.97,5.47
                    l-3.09,5.52l26.95,15.1L118.12,191.89z M117.44,184.11l-0.89,1.58l-19.83-11.11l0.89-1.58c0.94-1.69,1.95-3.08,2.98-4.14
                    c1-1.02,2.29-1.8,3.82-2.33c3.11-1.1,6.24-0.75,9.32,0.96c2.93,1.64,4.81,4.07,5.59,7.23c0.39,1.61,0.43,3.14,0.1,4.57
                    C119.08,180.75,118.41,182.37,117.44,184.11z"/>

                                        <rect x="121.23" y="137.88" transform="matrix(0.6099 -0.7925 0.7925 0.6099 -73.5611 157.856)" fill={this.props.colorTextRandom} width="4.64" height="31.52" />
                                        <path fill={this.props.colorTextRandom} d="M130.86,144.03c1.49,1.56,3.17,2.77,5.02,3.6c1.87,0.84,3.94,1.3,6.17,1.37c4.48,0.15,8.39-1.31,11.61-4.31
                    c1.6-1.49,2.86-3.31,3.75-5.39c0.88-2.08,1.28-4.25,1.18-6.44c-0.09-2.06-0.57-3.91-1.44-5.49c-0.84-1.54-2.1-3.18-3.75-4.86
                    l-0.43-0.44l-10.12,9.46l3,3.21l6.55-6.13c1.41,1.74,1.94,3.78,1.6,6.22c-0.36,2.67-1.51,4.93-3.41,6.7
                    c-1.04,0.97-2.33,1.72-3.83,2.21c-1.53,0.5-3.08,0.72-4.61,0.67c-3.18-0.15-5.86-1.35-7.99-3.63c-2.23-2.38-3.33-5.25-3.28-8.53
                    c0.06-3.27,1.28-6.04,3.64-8.25c2.96-2.77,6.53-3.67,10.93-2.78l0.71,0.14l0.31-4.64l-0.57-0.1c-5.6-0.93-10.43,0.46-14.35,4.13
                    c-3.01,2.82-4.73,6.27-5.12,10.27C125.99,135.75,127.6,140.6,130.86,144.03z"/>
                                        <polygon fill={this.props.colorTextRandom} points="170.91,130.17 174.77,127.6 157.65,101.89 157.3,101.36 153.44,103.93 170.56,129.64 			" />
                                        <path fill={this.props.colorTextRandom} d="M185.28,90.99l-1.98-3.93l-17.26,8.69l1.98,3.93l6.18-3.11c0.19-0.1,0.42-0.02,0.52,0.17l12.03,23.88
                    l4.14-2.08l-12.03-23.88c-0.1-0.19-0.02-0.42,0.17-0.52L185.28,90.99z"/>
                                        <path fill={this.props.colorTextRandom} d="M197.31,114.86l4.84-1.54l0.85-9.04c0.01-0.15,0.12-0.28,0.27-0.33l11.02-3.51c0.15-0.05,0.31,0,0.41,0.12
                    l5.85,6.9l4.87-1.55l-23.59-27.23c-0.22-0.25-0.64-0.12-0.67,0.21L197.31,114.86z M211.44,96.73l-7.93,2.52l1.02-10.67
                    L211.44,96.73z"/>
                                        <path fill={this.props.colorTextRandom} d="M235.58,100.29c-0.21,0.03-0.41-0.11-0.44-0.33l-3.87-26.47l-4.59,0.68l4.5,30.81
                    c0.03,0.21,0.23,0.36,0.44,0.33l12.46-1.82l-0.63-4.35L235.58,100.29z"/>
                                        <path fill={this.props.colorTextRandom} d="M294.65,74.62l-4.6-0.61l-1.56,11.8c-0.03,0.21-0.22,0.36-0.43,0.33l-13.34-1.77
                    c-0.21-0.03-0.36-0.22-0.33-0.43l1.56-11.79l-4.6-0.61l-4.06,30.62l-0.09,0.63l4.6,0.61l1.9-14.34c0.03-0.21,0.22-0.36,0.43-0.33
                    l13.34,1.77c0.21,0.03,0.36,0.22,0.33,0.43l-1.9,14.34l4.6,0.61l4.06-30.62L294.65,74.62z"/>
                                        <path fill={this.props.colorTextRandom} d="M296.07,106.89l4.78,1.7l6.14-6.69c0.1-0.11,0.27-0.15,0.41-0.1l10.91,3.86c0.15,0.05,0.25,0.19,0.26,0.34
                    l0.49,9.03l4.82,1.71l-2.34-35.95c-0.02-0.34-0.43-0.48-0.66-0.24L296.07,106.89z M318.28,100.98l-7.85-2.78l7.26-7.89
                    L318.28,100.98z"/>
                                        <path fill={this.props.colorTextRandom} d="M350.63,118.17c-0.52,0.86-0.93,1.51-1.23,1.96c-0.28,0.42-0.72,0.9-1.3,1.43c-0.56,0.52-1.15,0.89-1.74,1.1
                    c-0.93,0.32-1.95,0.41-3.02,0.28c-1.1-0.13-2.07-0.46-2.93-0.99c-0.9-0.54-1.68-1.3-2.33-2.26c-0.64-0.94-1.01-1.91-1.09-2.88
                    c-0.06-0.64,0.05-1.37,0.3-2.16c0.33-1.03,0.58-1.56,0.73-1.83c0.23-0.42,0.64-1.11,1.24-2.08l9.64-15.97l-3.97-2.4l-10.12,16.76
                    c-1.07,1.76-1.8,3.37-2.16,4.76c-0.39,1.45-0.35,3.04,0.11,4.75c0.78,3.01,2.6,5.39,5.39,7.07c0.49,0.3,0.99,0.56,1.49,0.78
                    c2.5,1.12,5.12,1.3,7.76,0.55c1.63-0.44,2.97-1.17,3.98-2.14c0.97-0.94,1.98-2.27,3-3.96l10.12-16.76l-3.97-2.4L350.63,118.17z"
                                        />
                                        <path fill={this.props.colorTextRandom} d="M376.86,133.11c-0.27-0.85-0.67-1.87-1.2-3.03l-0.91-1.99c-0.32-0.7-0.55-1.25-0.69-1.66
                    c-0.14-0.4-0.29-0.92-0.44-1.56c-0.13-0.56-0.15-1.08-0.03-1.53c0.12-0.46,0.34-0.88,0.68-1.26c0.79-0.88,1.73-1.31,2.88-1.32
                    c1.17,0,2.2,0.41,3.1,1.21c1.49,1.33,1.98,3.07,1.5,5.32l-0.14,0.65l4.39,0.71l0.14-0.57c0.43-1.76,0.38-3.47-0.12-5.1
                    c-0.51-1.62-1.45-3.05-2.78-4.24c-1.83-1.64-3.97-2.5-6.3-2.54c-2.37-0.05-4.4,0.86-6.04,2.7c-1.41,1.58-2.07,3.27-1.98,5.02
                    c0.09,1.66,0.64,3.62,1.63,5.82l0.84,1.82c0.34,0.77,0.58,1.33,0.72,1.68c0.14,0.34,0.3,0.86,0.48,1.54
                    c0.17,0.63,0.25,1.15,0.23,1.53c-0.02,0.39-0.13,0.84-0.32,1.36c-0.18,0.5-0.48,0.98-0.87,1.42c-0.99,1.11-2.21,1.66-3.72,1.68
                    c-1.52,0.02-2.84-0.48-3.95-1.47c-1.06-0.95-1.63-2.09-1.73-3.48c-0.1-1.41,0.25-2.72,1.06-3.91l0.41-0.6l-4.25-2.08l-0.3,0.51
                    c-1.27,2.19-1.75,4.49-1.42,6.82c0.33,2.34,1.47,4.39,3.37,6.09c0.85,0.76,1.77,1.36,2.74,1.79c1.4,0.63,2.92,0.91,4.55,0.84
                    c2.74-0.13,5.08-1.25,6.93-3.32c0.82-0.92,1.42-1.88,1.77-2.87c0.37-1.01,0.49-2.07,0.37-3.14
                    C377.34,134.93,377.14,133.98,376.86,133.11z"/>
                                    </g>
                                    <path fill={this.props.colorTextRandom} d="M259.42,428.42c-68.34,1.5-131.16-37.57-160.03-99.55c-1.08-2.32-0.08-5.09,2.25-6.17
                c2.33-1.09,5.09-0.07,6.17,2.25c27.32,58.64,86.76,95.61,151.42,94.19c89.87-1.97,161.37-76.69,159.4-166.55
                c-0.54-24.54-6.37-48.01-17.34-69.75c-1.76-3.49-3.67-6.96-5.67-10.3c-1.32-2.2-0.6-5.05,1.6-6.37c2.2-1.32,5.04-0.61,6.37,1.6
                c2.12,3.53,4.13,7.2,6,10.89c11.6,22.99,17.77,47.79,18.33,73.73C429.98,347.37,354.4,426.34,259.42,428.42z"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <HeaderComponent colorTextRandom={this.props.colorTextRandom} homeFlag={homeFlag} />
                    <div className='bodyBanner d-flex justify-content-between' style={{ color: this.props.colorTextRandom }}>
                        <div className='left '>
                            <NavLink to="/work" className="text-decoration-none text-reset">
                                <div className='w-100'>WORK</div>
                            </NavLink>
                        </div>
                        <div className='right '>
                            <NavLink to="/company" className="text-decoration-none text-reset">
                                <div className='w-100'>COMPANY</div>
                            </NavLink>
                        </div>
                    </div>
                    <div className='footerBanner'></div>
                </div>
            </>
        );
    }
};

export default HomeComponent;