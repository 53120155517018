import React, { Component, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom/dist';
import LogoHeaderComponent from './logoHeader';
/** Estilos componente */
import '../Styles/headerStyles.css';


class HeaderComponent extends Component {
    render() {
        let colorTextRandom = this.props?.colorTextRandom ?? "#ffff"; 
        let homeFlag = this.props?.homeFlag;
        let workFlag = this.props?.workFlag;
        let companyFlag = this.props?.companyFlag;
        return (
            <>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="logoHeader">
                    { homeFlag ? (
                        <NavLink to="/" className="text-decoration-none text-reset"> 
                            <i style={{color: colorTextRandom}} className="fa-solid fa-caret-left"></i>
                        </NavLink>
                    ) : workFlag ? (
                        <NavLink to="/work" className="text-decoration-none text-reset"> 
                            <i style={{color: colorTextRandom}} className="fa-solid fa-caret-left"></i>
                        </NavLink>

                    ) : companyFlag ? (
                        <NavLink to="/company" className="text-decoration-none text-reset"> 
                            <i style={{color: colorTextRandom}} className="fa-solid fa-caret-left"></i>
                        </NavLink>
                    ) : (

                        <LogoHeaderComponent colorTextRandom={colorTextRandom}/>
                    ) }
                    </div>
                    <div className='headerBanner d-flex align-items-center justify-content-end' >
                        <a href="https://www.linkedin.com/company/the-digital-haus" target='_blank'>
                            <i style={{color:colorTextRandom}} className="fa-brands fa-linkedin">   </i>
                        </a>
                        <a href="https://www.facebook.com/TheDigitalHaus/?locale=ms_MY" target='_blank'>
                            <i style={{color:colorTextRandom}} className="fa-brands fa-facebook"></i>
                        </a>
                        <a href="https://www.instagram.com/thedigitalhaus/?hl=en" target='_blank'>
                            <i style={{color:colorTextRandom}} className="fa-brands fa-instagram"></i>
                        </a>
                        <a href="https://twitter.com/TheDigitalHaus" target='_blank'>
                            <i style={{color:colorTextRandom}} className="fa-brands fa-twitter"></i>
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=4772808903" target='_blank'>
                            <i style={{color:colorTextRandom}} className="fa-brands fa-whatsapp"></i>
                        </a>
                        <a href="https://www.tiktok.com/@thedigitalhaus" target='_blank'>
                            <i style={{color:colorTextRandom}} className="fa-brands fa-tiktok"></i>
                        </a>
                    </div>
                </div>
            </>
        );
    }
};

export default HeaderComponent;