import React, { useEffect, useState } from 'react';

const CalendlyWidget = () => {
  // const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js'; 
    head.appendChild(script);
    // script.onload = () => {
    //   setIsLoading(false);
    // };
    return () => {
      head.removeChild(script);
    };
  }, [])
  return (
    <div>
      <div className="calendly-inline-widget" data-url="https://calendly.com/thedigitalhaus/book-meeting" style={{ minWidth: '300px', height: '1000px', overflow : 'hidden' }}/>
    </div>
  );
};

export default CalendlyWidget;
