 import React, { Component } from 'react';
import FooterComponent from './footer';
import HeaderComponent from './header';
/** Brands */
import logo_arcos from '../assets/brands/arcqos.svg'
import logo_cactus from '../assets/brands/cactus.svg'
import logo_cat from '../assets/brands/cat.svg' 
import logo_colortek from '../assets/brands/colortek.svg'
import logo_ecglove from '../assets/brands/ecglove.svg'
import logo_kovas from '../assets/brands/kovas.svg'
import logo_lassalinas from '../assets/brands/lassalinas.svg'
import logo_loco from '../assets/brands/loco.svg'
import logo_maravillas from '../assets/brands/maravillas.svg'
import logo_miedmar from '../assets/brands/medmar.svg'
import logo_ninewest from '../assets/brands/ninewest.svg'
import logo_open from '../assets/brands/open.svg'
import logo_rakennus from '../assets/brands/rakennus.svg'
import logo_rotary from '../assets/brands/rotary.svg'
import logo_stevemadden from '../assets/brands/stevemadden.svg'
import logo_westies from '../assets/brands/westies.svg'
/** Estilos componente */
import '../Styles/brandsStyles.css'



class BrandsComponent extends Component {

    render() {
        let colorRandom = this.props.colorRandom;
        let companyFlag = this.props?.companyFlag;
        let brands = [logo_arcos,logo_cactus,logo_cat,logo_colortek,logo_ecglove,logo_kovas,logo_lassalinas,logo_loco,logo_maravillas,logo_miedmar,logo_ninewest,logo_open,logo_rakennus,logo_rotary,logo_stevemadden,logo_westies]
        return (
            <>
                <div style={{ background: colorRandom, color:'#ffff' }}>
                    <HeaderComponent companyFlag={companyFlag}/>
                </div>
                <div className="containerBrands">
                    <br />
                    <div className='brandLabel fw-bold text-center' style={{color : colorRandom}}>BRANDS</div>
                    <div className="brandsContainer p-5 row" style={{ background: colorRandom, color : '#ffff'}}>
                        {brands.map((index) =>
                            (<>
                                <div className="brand col-6 col-lg-3 col-md-4 ">
                                    <img src={index} style={{'object-fit': 'cover'}} alt='---'/>
                                </div>
                            </>)
                        )}
                    </div>
                    <br /><br /><br />
                </div>
                <div style={{ background: colorRandom}}>
                    <FooterComponent/>
                </div>
            </>
        );
    }
};

export default BrandsComponent;
