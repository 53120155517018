import React, { Component } from 'react';
import FooterComponent from './footer';
import HeaderComponent from './header';
/** Calendario */
import CalendlyWidget from './CalendlyWidget';
/** Estilos componente */
import '../Styles/contactStyles.css'


class ContactComponent extends Component {
    render() {
        let colorRandom = this.props.colorRandom;
        let companyFlag = this.props?.companyFlag;
        return (
            <>
            <div style={{ background: colorRandom, color:'#ffff' }}>
                <HeaderComponent companyFlag={companyFlag}/>
            </div>
            <div className="contactContainer container"  style={{ color: colorRandom }}>
                <div className='contact'><br />
                   <h1 className='w-100 text-left fuenteLight fw-bold' >READY TO <b className='fuenteBold'>START A PROJECT</b></h1>
                    <div className="linea" style={{ background: colorRandom }} ></div>
                    <br />
                    <p className='h5 fw-bold'>info@thedigitalhaus.com</p>
                    <p className='h5 fw-bold'>USA +1 (986) 213-5926</p>
                    <p className='h5 fw-bold'>MX +52 477 280 8903</p>
                </div>
                <div className="calendarioContainer"> 
                    <CalendlyWidget/>     
                </div>
            </div>
            <div style={{ background: colorRandom }}>
                <FooterComponent/>
            </div>
            </>
        );
    }
};

export default ContactComponent;
