import React from 'react';
import { NavLink } from 'react-router-dom/dist';
/** Estilos componente */

function Logo404(props) {
    let colorRandom = props.colorRandom;
    let colorTextRandom = props.colorTextRandom;
    console.log("props : ", props)
    return (
        <>
            <svg version="1.1" id="Capa_1"  x="0px" y="0px"
                viewBox="0 0 1920 1080" >
                <g >
                    <g>
                        <g>
                            <path fill={ colorRandom} d="M497.1,670.4c0.9,5.3,2.7,9.4,5.7,12.4c2.9,3,6.6,4.4,11,4.4c3.4,0,6.2-0.9,8.3-2.8c2.1-1.9,3.2-4.4,3.2-7.7
				c0-1.7-0.2-3.2-0.7-4.3c-0.4-1.2-1-2.3-1.8-3.1s-1.9-1.5-3.3-2.1c-0.8-0.3-1.7-0.7-2.8-1c-1.1-0.4-2.1-0.7-3-1
				c-0.9-0.2-1.8-0.5-2.7-0.8c-1.2-0.4-2.4-0.9-3.4-1.3c-1.5-0.6-3.7-1.8-6.4-3.4c-2.8-1.7-4.2-4.8-4.2-9.6c0-4.2,1.6-7.7,4.9-10.6
				c3.3-2.9,7.3-4.4,11.9-4.4c2.1,0,4,0.3,5.7,0.9l4.1,1.4c1,0.3,1.8,0.5,2.5,0.5c1.7,0,2.7-0.8,3-2.3l1.1,0.1l0.1,15.3l-0.9,0.2
				l-0.7-2.6c-1.1-3.7-2.5-6.3-4.2-7.8s-3.5-2.6-5.2-3.3s-3.3-1-4.8-1c-3.1,0-5.7,1-7.8,3.1c-2.1,2.1-3.1,4.6-3.1,7.5
				c0,3.2,1,5.3,2.9,6.5c0.9,0.5,1.8,1,2.7,1.5c0.9,0.5,1.7,0.8,2.5,1.2c1.6,0.6,3.7,1.3,6.3,2.1s4.7,1.5,6.2,2.2
				c1.2,0.5,3,1.7,5.2,3.4s3.3,4.6,3.3,8.7c0,4.6-1.7,8.4-5.2,11.5s-7.9,4.6-13.2,4.6c-1.3,0-2.3-0.1-3-0.2c-0.7-0.1-1.4-0.3-2-0.4
				c-0.6-0.1-1.2-0.3-1.8-0.5l-4.8-1.8c-0.4-0.1-0.9-0.3-1.7-0.4c-1.7,0-2.9,0.9-3.6,2.7h-1.1l-0.1-17.4L497.1,670.4z"/>
                            <path fill={ colorRandom} d="M565.1,635.3c7.5,0,13.9,2.5,19,7.6c5.1,5,7.7,11.4,7.7,19s-2.5,13.9-7.6,19s-11.4,7.6-19.1,7.6
				c-7.6,0-13.9-2.6-19-7.7c-5.1-5.1-7.6-11.4-7.6-18.9c0-7.6,2.5-13.9,7.6-19C551.1,637.8,557.5,635.3,565.1,635.3z M547,661.9
				c0,8.2,1.6,14.5,4.7,18.8c3.1,4.3,7.6,6.4,13.5,6.4c5.8,0,10.3-2.2,13.5-6.5s4.7-10.6,4.7-18.8s-1.6-14.5-4.7-18.8
				c-3.1-4.3-7.6-6.5-13.5-6.5c-5.8,0-10.3,2.2-13.4,6.5C548.6,647.4,547,653.7,547,661.9z"/>
                            <path fill={ colorRandom} d="M596.5,636h24.6c5,0,8.6,0.7,10.8,2.2s3.8,3.1,4.8,4.9c1,1.8,1.5,3.7,1.5,5.7c0,3.7-0.9,6.4-2.7,8.1
				c-0.8,0.7-1.5,1.3-2.2,1.9c-0.7,0.5-1.3,1-1.9,1.3l-4.3,1.6c1.1,0.3,2,0.6,2.6,0.9c0.6,0.3,1.3,0.9,2.1,1.8
				c0.9,0.8,1.8,2.1,2.8,4.1s2,4.1,2.8,6.5l2,6.4c0.6,1.8,1.3,3,2.1,3.8c0.8,0.6,2.1,1.1,4.1,1.4l-0.1,1.1h-12.7l-3-10.4
				c-0.5-1.5-1.1-3.2-1.9-5.1c-0.4-0.9-0.8-1.8-1.3-2.8s-1.1-2-1.8-3.1c-1.3-2.1-4.3-3.2-9-3.2h-4.5v13.2c0,4.1,0.7,6.8,2,8.3
				c0.7,0.6,1.5,1.1,2.4,1.5c0.4,0.1,0.9,0.3,1.5,0.4s1.3,0.1,2.1,0.1v1.1h-22.5v-1.1c1.4,0,2.5-0.2,3.5-0.6s1.7-1,2.2-1.7
				c1-1.4,1.4-4,1.4-7.9v-28.9c0-4.1-0.6-6.8-1.8-8.3c-1.1-1-2.9-1.7-5.6-2L596.5,636L596.5,636z M611.4,661.6h5.1
				c4.2,0,7.5-1.1,9.9-3.3s3.5-5.2,3.5-9.1c0-3.7-0.8-6.4-2.5-8c-1.6-1.6-3.2-2.6-4.7-3s-3.3-0.6-5.6-0.6h-5.7V661.6z"/>
                            <path fill={ colorRandom} d="M645.1,636h24.6c5,0,8.6,0.7,10.8,2.2s3.8,3.1,4.8,4.9c1,1.8,1.5,3.7,1.5,5.7c0,3.7-0.9,6.4-2.7,8.1
				c-0.8,0.7-1.5,1.3-2.2,1.9c-0.7,0.5-1.3,1-1.9,1.3l-4.3,1.6c1.1,0.3,2,0.6,2.6,0.9c0.6,0.3,1.3,0.9,2.1,1.8
				c0.9,0.8,1.8,2.1,2.8,4.1s2,4.1,2.8,6.5l2,6.4c0.6,1.8,1.3,3,2.1,3.8c0.8,0.6,2.1,1.1,4.1,1.4l-0.1,1.1h-12.7l-3-10.4
				c-0.5-1.5-1.1-3.2-1.9-5.1c-0.4-0.9-0.8-1.8-1.3-2.8s-1.1-2-1.8-3.1c-1.3-2.1-4.3-3.2-9-3.2h-4.5v13.2c0,4.1,0.7,6.8,2,8.3
				c0.7,0.6,1.5,1.1,2.4,1.5c0.4,0.1,0.9,0.3,1.5,0.4s1.3,0.1,2.1,0.1v1.1h-22.5v-1.1c1.4,0,2.5-0.2,3.5-0.6s1.7-1,2.2-1.7
				c1-1.4,1.4-4,1.4-7.9v-28.9c0-4.1-0.6-6.8-1.8-8.3c-1.1-1-2.9-1.7-5.6-2L645.1,636L645.1,636z M659.9,661.6h5.1
				c4.2,0,7.5-1.1,9.9-3.3s3.5-5.2,3.5-9.1c0-3.7-0.8-6.4-2.5-8c-1.6-1.6-3.2-2.6-4.7-3s-3.3-0.6-5.6-0.6h-5.7V661.6z"/>
                            <path fill={ colorRandom} d="M686.8,636h21.6v1.1c-1.9,0-3.2,0.2-4,0.7c-0.3,0.2-0.5,0.4-0.6,0.5c-0.3,0.4-0.5,0.9-0.5,1.5
				c0,1.3,1,3.8,3.1,7.5l8.6,15.4l9.5-15.3c2-3.3,3.1-5.7,3.1-7.3c0-1-0.5-1.7-1.4-2.2s-2.5-0.8-4.7-0.8V636h15.9v1.1
				c-1.9,0.2-3.5,0.9-4.9,2.1l-1.7,1.7l-2.2,2.9l-2.3,3.7l-8.4,13.6l-0.6,1l-0.4,0.7l-0.9,1.8l-0.5,2.4v1.4v7.7
				c0,2.8,0.1,4.7,0.4,5.7c0.2,1,0.7,1.9,1.4,2.6c0.7,0.6,1.6,1.1,2.7,1.6c1.2,0.3,2.6,0.5,4.1,0.5v1.1h-24v-1.1
				c3-0.3,5.1-1.2,6.3-2.6c1.2-1.5,1.8-4,1.8-7.7V669c0-1.3-0.1-2.3-0.2-3c-0.1-0.6-0.3-1.1-0.4-1.4c-0.1-0.3-0.3-0.7-0.7-1.2
				l-1-1.8l-8-14.3c-2.1-3.8-3.9-6.4-5.3-7.7c-1.5-1.3-3.4-2.2-5.7-2.6L686.8,636L686.8,636z"/>
                            <path fill={ colorRandom} d="M742.3,678.2c1.3,0,2.5,0.5,3.5,1.5s1.5,2.2,1.5,3.6c0,1.5-0.5,2.7-1.4,3.7c-1,1-2.1,1.5-3.5,1.5
				c-1.5,0-2.7-0.5-3.8-1.4c-1-1-1.5-2.1-1.5-3.5s0.5-2.7,1.6-3.8c0.5-0.5,1-0.9,1.6-1.1C740.9,678.3,741.6,678.2,742.3,678.2z
				 M742.9,674l-0.5,0.3l-0.6-0.3l-0.1-1.2l-3-29.5l-0.2-3.3c0-1.4,0.4-2.6,1.1-3.6c0.7-1,1.7-1.5,2.8-1.5s2,0.5,2.8,1.5
				s1.1,2.2,1.1,3.6l-0.2,3.3l-3,29.5L742.9,674z"/>
                            <path fill={ colorRandom} d="M771.7,686.6c1.4,0,2.6-0.1,3.5-0.4c1-0.3,2-0.9,3.1-1.9s1.6-3.6,1.6-7.9v-28.9c0-3.8-0.5-6.4-1.5-7.7
				s-3.1-2.2-6.4-2.6V636h22.6c5.2,0,9,0.7,11.5,2s4.4,3,5.7,5s1.9,4.1,1.9,6.3c0,2.1-0.6,4.2-1.7,6.1c-1.1,2-2.6,3.5-4.4,4.6
				c-1.6,1-3.3,1.7-5.1,2.1c-0.8,0.1-1.8,0.3-3,0.4c-1.2,0.1-2.6,0.1-4.2,0.1h-8v13.7c0,4,0.6,6.8,1.7,8.2c1.1,1.4,3.4,2.1,6.8,2.1
				v1.1h-24.2v-1.1H771.7z M787.4,661h4.7c4.4,0,7.7-1,9.9-2.9c1-0.9,1.8-2.2,2.5-3.9s1-3.4,1-5.1c0-1.7-0.3-3.4-0.9-5
				c-0.6-1.6-1.4-2.9-2.5-3.9c-0.9-1-2-1.6-3.4-2s-3.3-0.6-5.7-0.6h-5.5L787.4,661L787.4,661z"/>
                            <path fill={ colorRandom} d="M804.8,686.7c2.1-0.3,3.6-0.9,4.6-1.9s1.9-2,2.5-3.3c0.7-1.2,1.5-3,2.4-5.3l15.8-39.1l0.4-1l0.5-0.3h0.4
				l0.2,0.2l0.3,1l16.3,39.2c1.8,4.2,3.2,6.8,4.2,7.8s2,1.6,2.7,2c0.3,0.1,0.7,0.2,1.1,0.3c0.5,0.1,1,0.2,1.7,0.3v1.1h-22.3v-1.1
				c2.4-0.2,4-0.6,4.9-1.1s1.4-1.4,1.4-2.5c0-1.6-0.6-3.8-1.7-6.6l-3.8-9h-16.9l-3.6,9c-1.2,2.7-1.7,4.8-1.7,6.4
				c0,1.2,0.5,2.2,1.6,2.9c1.1,0.6,2.8,0.9,5.2,0.9v1.1h-16.4v-1H804.8z M820.3,665.7h15.8l-7.9-19.3L820.3,665.7z"/>
                            <path fill={ colorRandom} d="M900.8,636.4l1,16.2l-0.9,0.1l-1.6-4.6c-0.3-0.8-1-2.1-2.2-4.2c-1.2-2-3.1-3.8-5.7-5.3
				c-2.6-1.5-5.5-2.3-8.5-2.3c-5.7,0-10.3,2.4-13.7,7.2s-5.1,11.3-5.1,19.6c0,6.1,1.1,10.9,3.2,14.6c2.1,3.7,4.4,6.2,6.7,7.6
				s5,2.1,8.2,2.1c3.9,0,6.9-1,9.1-3c2.2-2,3.3-4.9,3.3-8.5c0-2.4-0.5-4.2-1.6-5.2s-3-1.7-5.9-2v-0.9h21v1c-4.1,0.6-6.2,2.4-6.2,5.5
				v2.9v8.6l-0.9,0.1c-0.5-1-1.2-1.4-1.9-1.4c-0.6,0-1.1,0.1-1.5,0.4l-3.2,1.4c-1.6,0.7-3.6,1.3-5.9,1.7c-2.3,0.4-4.6,0.6-6.7,0.6
				c-7.3,0-13.5-2.5-18.5-7.6c-5-5-7.5-11.3-7.5-18.8c0-2.7,0.3-5.2,1-7.5c0.3-1.3,1-2.9,1.9-4.7s2.2-4.2,3.9-7
				c3.2-5.4,9.7-8.2,19.7-8.2h1c1.4,0,2.5,0.1,3.3,0.3c0.4,0,0.9,0.1,1.5,0.3c0.6,0.1,1.2,0.3,1.9,0.5l4.7,1.9
				c1,0.4,1.9,0.7,2.7,0.8c1.5,0,2.4-0.8,2.7-2.5L900.8,636.4z"/>
                            <path fill={ colorRandom} d="M907.7,686.6c1.6,0,2.9-0.2,3.7-0.5c1-0.3,2-0.9,3-2c1.1-1,1.6-3.6,1.6-7.8v-28.9c0-3.8-0.5-6.4-1.4-7.8
				c-1-1.4-3-2.2-6-2.5V636h40.3l1,13.3l-0.9,0.1c-0.7-2.5-1.6-4.5-2.6-5.9c-2.5-3.9-6.8-5.9-13-5.9h-9.8v22.1h1.9
				c3.1,0,5.6-0.6,7.5-1.8c1.2-0.7,2-1.6,2.4-2.5c0.4-0.8,0.6-1.5,0.8-2.3l0.5-2.9h0.9v20.9l-1,0.1c-0.4-3.9-1.5-6.6-3.2-8
				c-1.6-1.5-4.3-2.2-8.1-2.2h-1.7v10.8c0,5.9,0.5,9.6,1.4,11.1c0.6,1,1.7,1.9,3.3,2.6c1.6,0.7,3.6,1,5.8,1c2.4,0,4.6-0.4,6.7-1.2
				c2.2-0.8,3.8-1.6,4.7-2.5c1-0.9,1.7-1.7,2.4-2.5l1.6-2.4l1.1-2.5l0.8-3.1l0.9,0.1l-1.3,15.1h-43.3L907.7,686.6L907.7,686.6z"/>
                            <path fill={ colorRandom} d="M975.1,686.6c3.1,0,5.2-0.7,6.3-2.2s1.7-4.2,1.7-8.1v-28.8c0-3.8-0.5-6.4-1.4-7.7c-1-1.3-2.9-2.2-5.9-2.6
				V636h14l31.3,38.1v-26.6c0-4-0.5-6.6-1.6-7.9c-1-1.3-3.2-2.1-6.7-2.4V636h17.3v1.1c-3,0.3-4.9,1.1-5.9,2.4c-0.9,1.4-1.4,4-1.4,8
				v37.9v1.9l-0.2,0.4h-0.3l-0.7-0.7l-1.4-1.6l-35.4-43.5v34.4c0,4.1,0.8,6.8,2.3,8.3c0.6,0.5,1.5,1,2.7,1.4
				c0.5,0.1,1.1,0.3,1.8,0.4c0.6,0.1,1.4,0.1,2.4,0.1v1.1h-18.8L975.1,686.6L975.1,686.6z"/>
                            <path fill={ colorRandom} d="M1061.3,635.3c7.5,0,13.9,2.5,19,7.6c5.1,5,7.7,11.4,7.7,19s-2.5,13.9-7.6,19s-11.4,7.6-19.1,7.6
				c-7.6,0-13.9-2.6-19-7.7c-5.1-5.1-7.6-11.4-7.6-18.9c0-7.6,2.5-13.9,7.6-19C1047.3,637.8,1053.6,635.3,1061.3,635.3z
				 M1043.1,661.9c0,8.2,1.6,14.5,4.7,18.8s7.6,6.4,13.5,6.4c5.8,0,10.3-2.2,13.5-6.5s4.7-10.6,4.7-18.8s-1.6-14.5-4.7-18.8
				s-7.6-6.5-13.5-6.5c-5.8,0-10.3,2.2-13.4,6.5C1044.7,647.4,1043.1,653.7,1043.1,661.9z"/>
                            <path fill={ colorRandom} d="M1088.1,636h42.4l1.3,15.1h-1.1c-0.7-2.9-1.5-5.2-2.5-6.8c-0.8-1.6-1.8-2.8-2.9-3.7s-2.5-1.6-4.3-2.1
				c-1.7-0.5-3.3-0.8-4.9-0.8h-2.9v38.6c0,4.1,0.5,6.7,1.5,7.7c1,1.1,2,1.7,3,2.1c1,0.3,2.2,0.5,3.6,0.5v1.1h-23.9v-1.1
				c1.6,0,2.8-0.1,3.6-0.4c0.8-0.3,1.8-1,2.9-2c1.1-1.1,1.6-3.7,1.6-7.9v-38.6h-3.6c-2.2,0-3.9,0.2-5,0.5s-2.3,1-3.5,2
				c-1.1,1.1-2,2.1-2.7,3.2c-0.8,1.1-1.4,2.2-1.9,3.3l-1.1,3.6h-1L1088.1,636z"/>
                            <path fill={ colorRandom} d="M1152.8,686.6c1.4,0,2.6-0.1,3.5-0.4c0.9-0.3,1.7-0.8,2.4-1.6c0.6-0.6,1.1-1.6,1.6-3.1
				c0.2-0.7,0.3-1.5,0.4-2.3s0.1-1.8,0.1-2.9v-28.9v-1.8c0-0.9,0-1.5,0-2c0-0.8-0.1-1.6-0.4-2.5c-0.3-0.8-0.8-1.6-1.6-2.4
				c-0.7-0.8-2.5-1.3-5.4-1.6V636h39l1.1,13.2l-1,0.1c-0.2-0.9-0.4-1.5-0.4-1.7c-0.3-0.9-0.6-1.7-0.8-2.3c-0.3-0.6-1.2-2-2.9-4.3
				c-1.7-2.3-5.4-3.4-11-3.4h-9v21.9h1.6h0.7c3.2,0,5.5-0.5,7-1.5s2.4-2,3-3.2c0.5-1.1,0.8-2.8,0.8-5h1v20.9h-1.1
				c-0.4-3.7-1.5-6.3-3.1-7.8s-4.3-2.2-8-2.2h-1.9v15.5c0,4,0.6,6.7,1.8,8c1.1,1.1,3.2,1.9,6.2,2.3v1.1h-23.5L1152.8,686.6
				L1152.8,686.6z"/>
                            <path fill={ colorRandom} d="M1221.1,635.3c7.5,0,13.9,2.5,19,7.6c5.1,5,7.7,11.4,7.7,19s-2.5,13.9-7.6,19s-11.4,7.6-19.1,7.6
				c-7.6,0-13.9-2.6-19-7.7c-5.1-5.1-7.6-11.4-7.6-18.9c0-7.6,2.5-13.9,7.6-19C1207.1,637.8,1213.5,635.3,1221.1,635.3z M1203,661.9
				c0,8.2,1.6,14.5,4.7,18.8s7.6,6.4,13.5,6.4c5.8,0,10.3-2.2,13.5-6.5s4.7-10.6,4.7-18.8s-1.6-14.5-4.7-18.8s-7.6-6.5-13.5-6.5
				c-5.8,0-10.3,2.2-13.4,6.5C1204.6,647.4,1203,653.7,1203,661.9z"/>
                            <path fill={ colorRandom} d="M1252.7,636h21.7v1.1c-2.9,0-4.8,0.7-5.9,2.1c-1,1.4-1.4,4.1-1.4,8.1v23.3c0,3.7,0.2,6.3,0.6,7.8
				c0.4,1.6,1.6,3.2,3.7,5c2,1.8,5.5,2.7,10.4,2.7c2.3,0,4.4-0.3,6.4-0.9c2-0.6,3.7-1.7,5.1-3.2c1.2-1.2,2-2.5,2.5-4
				s0.7-3.6,0.7-6.5v-24.3c0-4.1-0.5-6.7-1.4-7.7s-1.8-1.7-2.7-2s-2.1-0.5-3.5-0.5v-1h15.9v1.1c-2.7,0.3-4.5,1.1-5.3,2.4
				c-0.9,1.3-1.3,3.9-1.3,7.8v24.8c0,2.8-0.2,4.9-0.8,6.4c-0.5,1.5-2,3.6-4.4,6.1s-6.9,3.8-13.6,3.8c-5.9,0-10.7-1.1-14.5-3.4
				c-2.1-1.1-3.5-2.6-4.2-4.4c-0.7-1.8-1.1-4.7-1.1-8.7v-24.6c0-3.8-0.4-6.4-1.3-7.8c-0.9-1.4-2.7-2.2-5.5-2.5L1252.7,636
				L1252.7,636z"/>
                            <path fill={ colorRandom} d="M1307.5,686.6c3.1,0,5.2-0.7,6.3-2.2c1.1-1.5,1.7-4.2,1.7-8.1v-28.8c0-3.8-0.5-6.4-1.4-7.7
				c-1-1.3-2.9-2.2-5.9-2.6V636h14l31.3,38.1v-26.6c0-4-0.5-6.6-1.6-7.9c-1-1.3-3.2-2.1-6.7-2.4V636h17.3v1.1
				c-3,0.3-4.9,1.1-5.9,2.4c-0.9,1.4-1.4,4-1.4,8v37.9v1.9l-0.2,0.4h-0.3l-0.7-0.7l-1.4-1.6l-35.4-43.5v34.4c0,4.1,0.8,6.8,2.3,8.3
				c0.6,0.5,1.5,1,2.7,1.4c0.5,0.1,1.1,0.3,1.8,0.4c0.6,0.1,1.4,0.1,2.4,0.1v1.1h-18.8v-1H1307.5z"/>
                            <path fill={ colorRandom} d="M1365.3,686.6c3.2-0.3,5.2-1.1,6.2-2.4c1-1.3,1.5-3.9,1.5-7.8v-29c0-3.8-0.5-6.3-1.4-7.7
				c-1-1.3-2.9-2.2-5.9-2.6V636h24.3c8.9,0,15.2,1.7,18.7,5c3.6,3.3,6.1,6.8,7.5,10.2c1.5,3.5,2.2,7.1,2.2,11c0,3-0.4,5.8-1.3,8.6
				s-2,5-3.3,6.7s-2.7,3.1-4.2,4.3c-1.4,1.1-3.1,2.2-5,3.2s-3.9,1.6-6.1,2.1c-1,0.2-2.2,0.3-3.5,0.4c-1.3,0.1-3,0.1-5.1,0.1h-24.7
				v-1H1365.3z M1380.6,637.6v39c0,2.4,0.1,4.2,0.4,5.2s1.1,2,2.5,3.1s3.6,1.6,6.6,1.6c1.5,0,2.8-0.1,3.9-0.2
				c1.1-0.1,2.2-0.4,3.1-0.8c1.9-0.7,4.5-2.6,7.8-5.8s5-9.1,5-17.7c0-7.3-1.4-12.7-4.1-16c-2.7-3.4-5.2-5.6-7.3-6.7
				c-2.1-1.1-4.5-1.6-7.1-1.6h-6.3L1380.6,637.6L1380.6,637.6z"/>
                        </g>
                    </g>
                    <g>
                        <rect x="66.6" y="1001.9" fill={ colorRandom} width="1766.9" height="4.1" />
                        <rect x="67.5" y="1025.1" fill={ colorRandom} width="1766.9" height="4.1" />
                    </g>
                    <ellipse fill={ colorTextRandom} cx="961.9" cy="469.1" rx="193.7" ry="22.4" />
                    <g>
                        <g>
                            <path fill={ colorRandom} d="M948.3,374.5c-11.7,0-22.3-1.7-31.3-4c-35.7-9.1-68-33.8-82.4-63c-13.9-28.3-19.8-54.6-18-80.4
				c2.2-30.6,15.6-60.1,40-87.7C908.8,80.2,1002,90.6,1005.9,91c3.8,0.4,6.4,3.8,6,7.6c-0.5,3.8-3.8,6.4-7.6,6l0,0
				c-0.9-0.1-90.1-9.9-137.5,43.8c-41.1,46.6-47.7,96.6-20,153c12.7,25.8,41.5,47.7,73.5,55.8c22.5,5.7,56.7,7.3,91.9-17.6
				c60-42.4,69-118.8,39.7-163.4c-24.4-37.3-53.1-55.7-87.7-56.3c-3.8-0.1-6.8-3.2-6.7-7c0.1-3.7,3.1-6.7,6.8-6.7h0.1
				c39.5,0.6,71.8,21.1,98.9,62.5c32.7,50,23.2,135.1-43.3,182.1C994.7,368.9,969.7,374.5,948.3,374.5z"/>
                            <path fill={ colorRandom} d="M1025.4,237.1l-14.6-18.9l14.5-18.2c2.4-3,1.9-7.3-1.1-9.6c-3-2.4-7.3-1.9-9.6,1.1l-12.4,15.5l-10.9-14.1
				c-2.3-3-6.6-3.6-9.6-1.2c-3,2.3-3.5,6.6-1.2,9.6l13,16.8L978.4,237c-2.4,3-1.9,7.3,1.1,9.6c1.3,1,2.8,1.5,4.3,1.5
				c2,0,4-0.9,5.4-2.6l13-16.3l12.5,16.2c1.3,1.8,3.4,2.7,5.4,2.7c1.5,0,2.9-0.5,4.2-1.4C1027.2,244.4,1027.8,240.1,1025.4,237.1z"
                            />
                            <path fill={ colorRandom} d="M914.6,237.7L900,218.9l14.5-18.2c2.4-3,1.9-7.3-1.1-9.6s-7.3-1.9-9.6,1.1l-12.4,15.5l-10.9-14.1
				c-2.3-3-6.6-3.6-9.6-1.2c-3,2.3-3.5,6.6-1.2,9.6l13,16.8l-15.1,18.9c-2.4,3-1.9,7.3,1.1,9.6c1.3,1,2.8,1.5,4.3,1.5
				c2,0,4-0.9,5.4-2.6l13-16.3l12.5,16.2c1.3,1.8,3.4,2.7,5.4,2.7c1.5,0,2.9-0.5,4.2-1.4C916.3,245,916.9,240.7,914.6,237.7z"/>
                        </g>
                        <path fill={ colorRandom} d="M1028,297.2c-44.4-32.5-83.9-9.9-85.6-8.9c-2.8,1.6-3.7,5.2-2.1,8c1.6,2.8,5.2,3.7,8,2.1c0.1,0,2.6-1.5,7-3
			c2.1,2.3,5.5,6.9,7.2,14.5c3.6,15.8,17.6,27.2,33.3,27.2c0.1,0,0.2,0,0.3,0c7.9-0.1,15.8-4,20.9-10.5c4.1-5.1,6.3-11.6,6.6-18.8
			c0.3,0,0.6,0.1,0.8,0.1c1.8,0,3.6-0.8,4.7-2.4C1031.2,302.8,1030.7,299.1,1028,297.2z M1008,319.2c-3,3.8-7.4,6-11.8,6.1
			c-0.1,0-0.1,0-0.2,0c-8.9,0-19.2-6.2-21.9-18.1c-1.5-6.4-3.8-11.3-6.2-14.9c5.2-0.8,11.2-1,17.8-0.2c1.3,2.5,4,9,3.7,17.7
			c-0.1,3.2,2.4,5.9,5.6,6.1c0.1,0,0.2,0,0.2,0c3.1,0,5.7-2.5,5.8-5.6c0.2-5.9-0.6-11-1.8-15.1c4,1.4,8.2,3.1,12.5,5.5
			C1013.2,310,1010.6,315.9,1008,319.2z"/>
                        <path fill={ colorRandom} d="M1143.2,216.8c-0.2-9.9-5.3-15.5-15.1-16.9c-2.7-0.4-5.5-0.5-9.1-0.7c-3.2-41.9-18.2-78.8-47.7-109.2
			c-29.7-30.6-66-47.8-108.5-51.2c-39-3-75.3,6-107.7,28.2c-46.4,31.8-70.8,76.7-75.2,132.1c-20.7,1.7-24.4,5.7-24.3,25.5
			c0,10.2,0,20.5,0,30.7c0,19.9,3.4,23.7,24.3,25.8c0,22.5,0.4,44.5-0.2,66.5c-0.2,7.5,2.4,11.8,8.6,15.6
			c41.2,24.9,85.5,40.3,133.5,44.8c68.2,6.4,130.8-10,189.2-45.1c6-3.6,8.3-7.7,8.2-14.9c-0.6-22.3-0.2-44.6-0.2-66.8
			c22.2-2.9,24.3-5.4,24.3-27.2C1143.2,241.7,1143.4,229.2,1143.2,216.8z M779.5,221.4c0,13.9,0,27.8,0,42.1c-4.8,1.3-7.8,1.1-7.7-5
			c0.2-12.4-0.2-24.7,0.2-37.1c0-0.2,0.1-0.4,0.1-0.6l0,0c0,0-0.7-7.8,4.2-8h3.4L779.5,221.4z M1103.2,267c0.3,24.5-0.2,49,0.2,73.5
			c0.1,6.1-2,9.6-7.1,12.6c-50.7,29.5-105,43.2-163.7,39.8c-45.7-2.7-88.1-15.9-127.8-38.5c-6.4-3.6-9.1-7.6-8.9-15.3
			c0.6-24.2,0.2-48.4,0.2-72.6c0.1,0,0.3,0,0.4,0c0-22.6-0.8-45.3,0.2-67.9c3-67.8,52.4-125.9,118.9-140.8
			c87.4-19.7,174,39.5,185.7,128.4C1104.8,212.8,1103,240.1,1103.2,267z M1127.3,263.7c-3,0-5.3,0-7.9,0c0-14.3,0-28.4,0-42.4
			l-0.4-9.3c9-2.7,8.2,10.1,8.2,10.1l0,0C1127.5,235.7,1127.3,249.5,1127.3,263.7z"/>
                    </g>
                    <path fill={ colorRandom} d="M1429.3,264.6v59.5h-36.1V386H1321v-61.9h-145.2v-49.6l115.4-187.7h102v177.7h36.1V264.6z M1321,149.6h-0.8
		l-69.8,115h70.6L1321,149.6L1321,149.6z"/>
                    <path fill={ colorRandom} d="M722.2,264.6v59.5h-36.1V386h-72.2v-61.9H468.7v-49.6L584.2,86.9h102v177.7H722.2z M613.9,149.6h-0.8
		l-69.8,115h70.6V149.6z"/>
                    <g>
                        <path fill={colorTextRandom} d="M539.5,858.6h16.1V845h4.2v33.8h-4.2v-16.2h-16.1v16.2h-4.2V845h4.2V858.6z" />
                        <path fill={colorTextRandom} d="M565.9,878.8V845H584v3.9h-13.9v9.5h13.5v3.9h-13.5v12.8H584v3.9h-18.1V878.8z" />
                        <path fill={colorTextRandom} d="M599.6,864.1l10.7,14.7h-5.1l-9.9-14.3h-1.7v14.3h-4.2V845h5.1c1.3,0,2.3,0,3.1,0.1c0.8,0,1.7,0.2,2.9,0.4
			c1.1,0.2,2.1,0.6,2.9,1.1c1.4,0.8,2.5,1.9,3.2,3.4c0.8,1.5,1.2,3,1.2,4.7c0,2.4-0.7,4.6-2.2,6.3
			C603.9,862.7,602,863.8,599.6,864.1z M593.4,848.7V861h1.3c1.2,0,2.3-0.1,3.2-0.2c0.9-0.1,1.9-0.4,2.8-0.8c0.9-0.4,1.6-1,2.2-1.9
			c0.5-0.9,0.8-1.9,0.8-3.2c0-4-3.1-6-9.2-6h-1.1V848.7z"/>
                        <path fill={colorTextRandom} d="M613.5,878.8V845h18.1v3.9h-13.9v9.5h13.5v3.9h-13.5v12.8h13.9v3.9h-18.1V878.8z" />
                        <path fill={colorTextRandom} d="M670.1,870.3h-15.5l-3.9,8.5h-4.5l16.1-35.2l16.1,35.2H674L670.1,870.3z M668.4,866.4l-6-13.7l-6,13.7H668.4z
			"/>
                        <path fill={colorTextRandom} d="M691.7,864.1l10.7,14.7h-5.1l-9.9-14.3h-1.7v14.3h-4.2V845h5.1c1.3,0,2.3,0,3.1,0.1c0.8,0,1.7,0.2,2.9,0.4
			c1.1,0.2,2.1,0.6,2.9,1.1c1.4,0.8,2.5,1.9,3.2,3.4c0.8,1.5,1.2,3,1.2,4.7c0,2.4-0.7,4.6-2.2,6.3
			C696,862.7,694.1,863.8,691.7,864.1z M685.5,848.7V861h1.3c1.2,0,2.3-0.1,3.2-0.2c0.9-0.1,1.9-0.4,2.8-0.8c0.9-0.4,1.6-1,2.2-1.9
			c0.5-0.9,0.8-1.9,0.8-3.2c0-4-3.1-6-9.2-6h-1.1V848.7z"/>
                        <path fill={colorTextRandom} d="M705.6,878.8V845h18.1v3.9h-13.9v9.5h13.5v3.9h-13.5v12.8h13.9v3.9h-18.1V878.8z" />
                        <path fill={colorTextRandom} d="M760.3,849.4l-3.4,2c-1.3-2.1-3.1-3.2-5.4-3.2c-1.4,0-2.7,0.4-3.9,1.3c-1.2,0.9-1.7,2-1.7,3.4
			c0,2,1.6,3.6,4.7,4.8l2.4,0.9c2.7,1,4.8,2.3,6.2,3.9c1.4,1.6,2.2,3.6,2.2,6.3c0,3-1,5.6-3.1,7.5c-2.1,2-4.6,3-7.7,3
			c-2.7,0-5-0.9-7-2.7c-1.9-1.8-3.1-4-3.4-6.8l4.3-0.9c0,1.8,0.6,3.3,1.9,4.6c1.3,1.2,2.8,1.8,4.7,1.8c1.8,0,3.3-0.6,4.5-1.9
			c1.2-1.3,1.8-2.8,1.8-4.6c0-1.6-0.5-2.9-1.5-3.9c-1-0.9-2.5-1.8-4.3-2.5l-2.3-1c-2.3-1-4.1-2.1-5.4-3.4s-1.9-3-1.9-5.2
			c0-2.6,1-4.7,3-6.3s4.3-2.4,7-2.4C755.5,844.3,758.4,846,760.3,849.4z"/>
                        <path fill={colorTextRandom} d="M768.8,849.4c3.5-3.4,7.6-5.2,12.5-5.2c4.8,0,9,1.7,12.5,5.2c3.5,3.4,5.2,7.6,5.2,12.4c0,5-1.7,9.2-5.2,12.5
			c-3.4,3.4-7.6,5.1-12.5,5.1s-9.1-1.7-12.5-5.1c-3.4-3.4-5.2-7.6-5.2-12.5C763.6,857,765.4,852.9,768.8,849.4z M771.8,871.6
			c2.7,2.6,5.8,4,9.5,4s6.8-1.3,9.5-4c2.7-2.6,4-5.9,4-9.7s-1.3-7-3.8-9.7c-2.6-2.7-5.8-4-9.7-4s-7.1,1.3-9.7,4
			c-2.6,2.7-3.8,5.9-3.8,9.7C767.8,865.7,769.2,868.9,771.8,871.6z"/>
                        <path fill={colorTextRandom} d="M818.9,879.5l-10.1-23h-0.1l-3.9,22.3h-4.3l6.9-35.2l11.6,26.6l11.6-26.6l6.9,35.2h-4.3l-3.9-22.3h-0.1
			L818.9,879.5z"/>
                        <path fill={colorTextRandom} d="M841.2,878.8V845h18.1v3.9h-13.9v9.5H859v3.9h-13.5v12.8h13.9v3.9h-18.2V878.8z" />
                        <path fill={colorTextRandom} d="M881.4,858.6h16.1V845h4.2v33.8h-4.2v-16.2h-16.1v16.2h-4.2V845h4.2V858.6z" />
                        <path fill={colorTextRandom} d="M907.7,878.8V845h18.1v3.9H912v9.5h13.5v3.9H912v12.8h13.9v3.9h-18.2V878.8z" />
                        <path fill={colorTextRandom} d="M935.3,845v29.9h10.3v3.9h-14.5V845H935.3z" />
                        <path fill={colorTextRandom} d="M952.5,864.6v14.2h-4.2V845h4.8c5,0,8.5,0.8,10.6,2.4c2,1.6,3.1,4.1,3.1,7.3c0,3.3-1.1,5.8-3.3,7.4
			c-2.2,1.6-5,2.4-8.5,2.4L952.5,864.6L952.5,864.6z M952.5,848.7v12.1h1.3c1.2,0,2.3-0.1,3.2-0.2c0.9-0.1,1.9-0.4,2.8-0.8
			c0.9-0.4,1.6-1,2.2-1.9c0.5-0.9,0.8-1.9,0.8-3.2s-0.3-2.3-0.8-3.1s-1.3-1.4-2.3-1.8c-1-0.4-1.9-0.6-2.9-0.8
			c-0.9-0.1-2-0.2-3.2-0.2L952.5,848.7L952.5,848.7z"/>
                        <path fill={colorTextRandom} d="M986.8,848.8h-12.1v9.5h11.7v3.9h-11.7v16.6h-4.2V845h16.4v3.8H986.8z" />
                        <path fill={colorTextRandom} d="M991.2,845h4.2v19.9c0,7.1,2.7,10.6,8.2,10.6c5.4,0,8.2-3.5,8.2-10.6V845h4.2v21.4c0,3.8-1.1,7-3.3,9.4
			c-2.2,2.5-5.2,3.7-9,3.7s-6.8-1.2-9-3.7s-3.3-5.6-3.3-9.4V845H991.2z"/>
                        <path fill={colorTextRandom} d="M1025.9,845v29.9h10.3v3.9h-14.5V845H1025.9z" />
                        <path fill={colorTextRandom} d="M1056.1,845v29.9h10.3v3.9h-14.5V845H1056.1z" />
                        <path fill={colorTextRandom} d="M1073.2,845v33.8h-4.2V845H1073.2z" />
                        <path fill={colorTextRandom} d="M1079.1,878.8v-35.4l25.4,26.6v-25h4.2v35.2l-25.4-26.6v25.2H1079.1z" />
                        <path fill={colorTextRandom} d="M1118.9,859.6l14.3-14.7h5.5l-15.2,15.2l15.5,18.6h-5.7l-12.7-15.7l-1.7,1.6v14.1h-4.2V845h4.2L1118.9,859.6
			L1118.9,859.6z"/>
                        <path fill={colorTextRandom} d="M1160,849.4l-3.4,2c-1.3-2.1-3.1-3.2-5.4-3.2c-1.4,0-2.7,0.4-3.9,1.3c-1.2,0.9-1.7,2-1.7,3.4
			c0,2,1.6,3.6,4.7,4.8l2.4,0.9c2.7,1,4.8,2.3,6.2,3.9c1.4,1.6,2.2,3.6,2.2,6.3c0,3-1,5.6-3.1,7.5c-2.1,2-4.6,3-7.7,3
			c-2.7,0-5-0.9-7-2.7c-1.9-1.8-3.1-4-3.4-6.8l4.3-0.9c0,1.8,0.6,3.3,1.9,4.6c1.3,1.2,2.8,1.8,4.7,1.8c1.8,0,3.3-0.6,4.5-1.9
			c1.2-1.3,1.8-2.8,1.8-4.6c0-1.6-0.5-2.9-1.5-3.9c-1-0.9-2.5-1.8-4.3-2.5l-2.3-1c-2.3-1-4.1-2.1-5.4-3.4s-1.9-3-1.9-5.2
			c0-2.6,1-4.7,3-6.3s4.3-2.4,7-2.4C1155.2,844.3,1158.1,846,1160,849.4z"/>
                        <path fill={colorTextRandom} d="M1182.7,845v33.8h-4.2V845H1182.7z" />
                        <path fill={colorTextRandom} d="M1188.6,878.8v-35.4L1214,870v-25h4.2v35.2l-25.4-26.6v25.2H1188.6z" />
                        <path fill={colorTextRandom} d="M1242.5,849.4l-3.4,2c-1.3-2.1-3.1-3.2-5.4-3.2c-1.4,0-2.7,0.4-3.9,1.3c-1.2,0.9-1.7,2-1.7,3.4
			c0,2,1.6,3.6,4.7,4.8l2.4,0.9c2.7,1,4.8,2.3,6.2,3.9c1.4,1.6,2.2,3.6,2.2,6.3c0,3-1,5.6-3.1,7.5c-2.1,2-4.6,3-7.7,3
			c-2.7,0-5-0.9-7-2.7c-1.9-1.8-3.1-4-3.4-6.8l4.3-0.9c0,1.8,0.6,3.3,1.9,4.6c1.3,1.2,2.8,1.8,4.7,1.8c1.8,0,3.3-0.6,4.5-1.9
			c1.2-1.3,1.8-2.8,1.8-4.6c0-1.6-0.5-2.9-1.5-3.9c-1-0.9-2.5-1.8-4.3-2.5l-2.3-1c-2.3-1-4.1-2.1-5.4-3.4s-1.9-3-1.9-5.2
			c0-2.6,1-4.7,3-6.3s4.3-2.4,7-2.4C1237.7,844.3,1240.6,846,1242.5,849.4z"/>
                        <path fill={colorTextRandom} d="M1257.3,848.8v29.9h-4.2v-29.9h-8V845h20.2v3.9h-8V848.8z" />
                        <path fill={colorTextRandom} d="M1268.7,878.8V845h18.1v3.9h-13.9v9.5h13.5v3.9h-13.5v12.8h13.9v3.9h-18.1V878.8z" />
                        <path fill={colorTextRandom} d="M1312.3,870.3h-15.5l-3.9,8.5h-4.5l16.1-35.2l16.1,35.2h-4.5L1312.3,870.3z M1310.5,866.4l-6-13.7l-6,13.7
			H1310.5z"/>
                        <path fill={colorTextRandom} d="M1323.1,878.8V845h7c5.5,0,10,1.4,13.6,4.3c3.6,2.9,5.4,7,5.4,12.4c0,5.5-1.8,9.8-5.3,12.7
			c-3.6,2.9-8.1,4.4-13.8,4.4L1323.1,878.8L1323.1,878.8z M1327.3,848.8v26.1h1.5c2.3,0,4.3-0.2,6.2-0.7c1.9-0.4,3.5-1.2,5-2.2
			s2.7-2.3,3.5-4.1c0.9-1.7,1.3-3.8,1.3-6.1c0-2.4-0.4-4.5-1.3-6.2s-2-3.1-3.6-4.1s-3.2-1.7-5-2.1c-1.8-0.4-3.9-0.7-6.2-0.7
			L1327.3,848.8L1327.3,848.8z"/>
                        <path fill={colorTextRandom} d="M1354.7,857.9c0.5-0.5,1.1-0.8,1.8-0.8s1.3,0.3,1.8,0.8s0.8,1.1,0.8,1.8s-0.3,1.3-0.8,1.8s-1.1,0.8-1.8,0.8
			s-1.3-0.3-1.8-0.8s-0.8-1.1-0.8-1.8C1354,859.1,1354.2,858.5,1354.7,857.9z M1354.7,875.1c0.5-0.5,1.1-0.8,1.8-0.8
			s1.3,0.3,1.8,0.8s0.8,1.1,0.8,1.8s-0.3,1.3-0.8,1.8s-1.1,0.8-1.8,0.8s-1.3-0.3-1.8-0.8s-0.8-1.1-0.8-1.8
			C1354,876.2,1354.2,875.6,1354.7,875.1z"/>
                    </g>
                    <g>
                        <path fill={ colorRandom} d="M801.9,603.1c2.1,0,3.7-0.2,4.8-0.6c1.2-0.4,2.5-1.2,3.8-2.5s2-4.7,2-10v-37c0-4.9-0.6-8.2-1.8-9.9
			s-3.8-2.8-7.7-3.3v-1.4h51.5l1.3,17l-1.2,0.2c-0.9-3.2-2-5.7-3.3-7.6c-3.2-5-8.7-7.5-16.7-7.5H822v28.3h2.4c3.9,0,7.1-0.8,9.6-2.3
			c1.5-0.9,2.5-2,3-3.2c0.5-1,0.8-2,1-2.9l0.6-3.7h1.2v26.7l-1.3,0.1c-0.5-5-1.9-8.4-4-10.2c-2.1-1.9-5.5-2.8-10.4-2.8H822v13.8
			c0,7.6,0.6,12.3,1.8,14.2c0.7,1.3,2.2,2.5,4.3,3.3c2.1,0.9,4.6,1.3,7.4,1.3c3.1,0,5.9-0.5,8.6-1.6c2.8-1,4.8-2,6-3.2
			c1.2-1.1,2.2-2.2,3-3.3l2-3l1.5-3.2l1-3.9l1.2,0.2l-1.6,19.3h-55.3V603.1z"/>
                        <path fill={ colorRandom} d="M862.3,538.4h31.4c6.4,0,10.9,0.9,13.8,2.8c2.8,1.9,4.9,4,6.1,6.3c1.3,2.3,1.9,4.7,1.9,7.2
			c0,4.8-1.2,8.2-3.5,10.4c-1,0.9-1.9,1.7-2.8,2.4c-0.9,0.7-1.7,1.2-2.4,1.6l-5.5,2.1c1.5,0.4,2.6,0.7,3.3,1.1
			c0.8,0.4,1.7,1.2,2.7,2.3c1.1,1,2.3,2.7,3.6,5.2s2.5,5.3,3.6,8.3l2.6,8.2c0.7,2.3,1.6,3.9,2.7,4.8c1,0.8,2.7,1.4,5.2,1.7l-0.1,1.4
			h-16.2l-3.9-13.3c-0.6-1.9-1.4-4.1-2.5-6.5c-0.5-1.2-1-2.4-1.7-3.6c-0.6-1.2-1.4-2.5-2.3-3.9c-1.6-2.7-5.5-4.1-11.5-4.1h-5.7v16.9
			c0,5.2,0.9,8.7,2.6,10.6c0.9,0.8,1.9,1.4,3,1.9c0.5,0.2,1.2,0.3,1.9,0.5c0.7,0.1,1.6,0.2,2.7,0.2v1.4h-28.8v-1.4
			c1.8,0,3.3-0.3,4.4-0.8c1.2-0.5,2.1-1.3,2.8-2.2c1.2-1.8,1.8-5.1,1.8-10.1v-37c0-5.2-0.8-8.7-2.3-10.6c-1.3-1.3-3.7-2.2-7.1-2.6
			v-1.2H862.3z M881.3,571.1h6.5c5.4,0,9.6-1.4,12.6-4.2s4.5-6.6,4.5-11.6c0-4.8-1.1-8.2-3.2-10.2c-2.1-2-4.1-3.3-6-3.8
			s-4.3-0.8-7.2-0.8h-7.3v30.6H881.3z"/>
                        <path fill={ colorRandom} d="M924.4,538.4h31.4c6.3,0,10.9,0.9,13.8,2.8c2.8,1.9,4.9,4,6.1,6.3c1.2,2.3,1.9,4.7,1.9,7.2
			c0,4.8-1.2,8.2-3.5,10.4c-1,0.9-1.9,1.7-2.8,2.4c-0.9,0.7-1.7,1.2-2.4,1.6l-5.5,2.1c1.5,0.4,2.6,0.7,3.3,1.1
			c0.8,0.4,1.7,1.2,2.7,2.3c1.1,1,2.3,2.7,3.6,5.2s2.5,5.3,3.6,8.3l2.6,8.2c0.7,2.3,1.6,3.9,2.7,4.8c1,0.8,2.7,1.4,5.2,1.7l-0.1,1.4
			h-16.2l-3.9-13.3c-0.6-1.9-1.4-4.1-2.5-6.5c-0.5-1.2-1-2.4-1.7-3.6c-0.6-1.2-1.4-2.5-2.3-3.9c-1.6-2.7-5.5-4.1-11.5-4.1h-5.7v16.9
			c0,5.2,0.9,8.7,2.6,10.6c0.9,0.8,1.9,1.4,3,1.9c0.5,0.2,1.2,0.3,1.9,0.5c0.7,0.1,1.6,0.2,2.7,0.2v1.4h-28.8v-1.4
			c1.8,0,3.3-0.3,4.4-0.8c1.2-0.5,2.1-1.3,2.8-2.2c1.2-1.8,1.8-5.1,1.8-10.1v-37c0-5.2-0.8-8.7-2.3-10.6c-1.3-1.3-3.7-2.2-7.1-2.6
			v-1.2H924.4z M943.4,571.1h6.5c5.4,0,9.6-1.4,12.6-4.2s4.5-6.6,4.5-11.6c0-4.8-1.1-8.2-3.2-10.2c-2.1-2-4.1-3.3-6-3.8
			s-4.3-0.8-7.2-0.8h-7.3v30.6H943.4z"/>
                        <path fill={ colorRandom} d="M1020.9,537.5c9.6,0,17.7,3.2,24.3,9.7c6.5,6.5,9.8,14.6,9.8,24.4c0,9.7-3.2,17.8-9.7,24.3
			s-14.6,9.7-24.4,9.7c-9.7,0-17.8-3.3-24.3-9.8s-9.8-14.6-9.8-24.2c0-9.7,3.2-17.8,9.7-24.3C1003,540.8,1011.1,537.5,1020.9,537.5z
			 M997.7,571.5c0,10.5,2,18.5,6,24s9.7,8.2,17.2,8.2c7.4,0,13.2-2.8,17.2-8.3s6-13.5,6-24s-2-18.5-6-24s-9.8-8.3-17.3-8.3
			c-7.5,0-13.2,2.8-17.2,8.3C999.7,553,997.7,561,997.7,571.5z"/>
                        <path fill={ colorRandom} d="M1061,538.4h31.4c6.4,0,10.9,0.9,13.8,2.8c2.8,1.9,4.9,4,6.1,6.3c1.3,2.3,1.9,4.7,1.9,7.2
			c0,4.8-1.2,8.2-3.5,10.4c-1,0.9-1.9,1.7-2.8,2.4s-1.7,1.2-2.4,1.6l-5.5,2.1c1.5,0.4,2.6,0.7,3.3,1.1c0.8,0.4,1.7,1.2,2.7,2.3
			c1.1,1,2.3,2.7,3.6,5.2c1.3,2.5,2.5,5.3,3.6,8.3l2.6,8.2c0.7,2.3,1.6,3.9,2.7,4.8c1,0.8,2.7,1.4,5.2,1.7l-0.1,1.4h-16.2l-3.9-13.3
			c-0.6-1.9-1.4-4.1-2.5-6.5c-0.5-1.2-1-2.4-1.7-3.6c-0.6-1.2-1.4-2.5-2.3-3.9c-1.6-2.7-5.5-4.1-11.5-4.1h-5.7v16.9
			c0,5.2,0.9,8.7,2.6,10.6c0.9,0.8,1.9,1.4,3,1.9c0.5,0.2,1.2,0.3,1.9,0.5c0.7,0.1,1.6,0.2,2.7,0.2v1.4h-28.8v-1.4
			c1.8,0,3.3-0.3,4.4-0.8s2.1-1.3,2.8-2.2c1.2-1.8,1.8-5.1,1.8-10.1v-37c0-5.2-0.8-8.7-2.3-10.6c-1.3-1.3-3.7-2.2-7.1-2.6v-1.2H1061
			z M1079.9,571.1h6.5c5.4,0,9.6-1.4,12.6-4.2s4.5-6.6,4.5-11.6c0-4.8-1.1-8.2-3.2-10.2s-4.1-3.3-6-3.8s-4.3-0.8-7.2-0.8h-7.3
			L1079.9,571.1L1079.9,571.1z"/>
                    </g>
                </g>
            </svg>

        </>
    );
};

export default Logo404;