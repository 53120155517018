import React, { Component, useEffect, useState } from 'react';
/** Estilos componente */
import '../Styles/footerStyles.css'


class FooterComponent extends Component {
    render() {
        let colorRandom = this.props.colorRandom;
        return (
            <>
                <div className="footerContainer w-100" style={{ background: colorRandom }}>
                    <div className="bordeSup"></div>
                    <div className="footer d-flex flex-wrap justify-content-between align-items-center p-3" >
                        <div className="labelText col-md-4 d-flex text-white align-items-center flex-column">
                            <div >We match?</div>
                            <div >Contact us!</div>
                        </div>

                    </div>
                    <div className="logoFooter">

                    </div>
                </div>
            </>
        );
    }
};

export default FooterComponent;
