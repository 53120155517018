import React, { Component } from 'react';
import FooterComponent from './footer';
import HeaderComponent from './header';
/** Estilos componente */
import '../Styles/pricingStyles.css'


class PricingComponent extends Component {

    render() {
        let colorRandom = this.props.colorRandom;
        let companyFlag = this.props?.companyFlag;
        return (
            <>
            <div style={{ background: colorRandom, color:'#ffff' }}>
                <HeaderComponent companyFlag={companyFlag}/>
            </div>
            <div className="containerPricing container fuenteLight">
                <br />
                <h1 className='w-100 text-center'  style={{ color: colorRandom }}>
                    DRIVE <b className='fw-bold'>REVENUE GROWTH</b>
                </h1>
                <br />
                <p className='w-100 text-center mb-0'>We merge research,strategic, planing </p>
                <p className='w-100 text-center mt-0'>and branding for your succes</p>
                <br />
                {/*  */}
                <h1 className='w-100 text-left'  style={{ color: colorRandom }}>MONTHLY <b className='fw-bold'>RETAINER</b></h1>
                <div className="linea" style={{ background: colorRandom }} ></div>
                <br />
                <p><b style={{ color: colorRandom }}>IDEAL FOR : </b> Companies with limited resources. </p>
                <p><b style={{ color: colorRandom }}>HOW IT WORKS : </b> The best full-service option for companies of all sizes. Get in touch to learn more about our goal-setting process, brand growth case studies and flexible packages that infuse your brand with the expert team you need to drive sales and profitability. </p>
                <br />
                {/*  */}
                <h1 className='w-100 text-left'  style={{ color: colorRandom }}>PROJECT - <b className='fw-bold'>BASED</b></h1>
                <div className="linea" style={{ background: colorRandom }} ></div>
                <br />
                <p><b style={{ color: colorRandom }}>IDEAL FOR : </b> Companies with specific projects. </p>
                <p><b style={{ color: colorRandom }}>HOW IT WORKS : </b> We work together with your team to outline specific areas of need and deliver a project proposal that clearly outlines goals, deliverables, milestones and the investment for the project at hand.
                </p>
                <br />
                {/*  */}
                <h1 className='w-100 text-left'  style={{ color: colorRandom }}>CONSULTING <b className='fw-bold'>CALL</b></h1>
                <div className="linea" style={{ background: colorRandom }} ></div>
                <br />
                <p><b style={{ color: colorRandom }}>IDEAL FOR : </b> Individuals, brands, private equity, legal or consulting firms looking for innovative solutions to meet their goals and objectives. </p>
                <p><b style={{ color: colorRandom }}>HOW IT WORKS : </b> For those times when you need to bounce ideas off an expert, without a big investment, we’ll schedule a Zoom session to connect and consult. Time is billed hourly, and the first hour is happily credited back if the project or retainer work is booked. </p>
                <br />
            </div>
            <div style={{ background: colorRandom }}>
                <FooterComponent/>
            </div>
            </>
        );
    }
};

export default PricingComponent;
