import React, { Component } from 'react';
import FooterComponent from './footer';
import HeaderComponent from './header';
/** Logos locations */
import logo_planeta1 from '../assets/brands/planeta1.svg'
/** Estilos componente */
import '../Styles/aboutStyles.css'


class AboutComponent extends Component {

    render() {
        let colorRandom = this.props.colorRandom;
        let locations = [logo_planeta1]
        let companyFlag = this.props?.companyFlag;
        return (
            <>
                <div style={{ background: colorRandom, color: '#ffff' }}>
                    <HeaderComponent companyFlag={companyFlag}/>
                </div>
                <div className="container aboutContainer fuenteLight">
                    <br />
                    <h1 className='fuenteBold text-center' style={{ color: colorRandom }} >One Team, Global Reach</h1>
                    <br />
                    <p>
                        Our team of <b style={{ color: colorRandom }} > international experts </b> are here to partner with you and help create a data-driven strategy tailored to your target market.
                    </p>
                    <p>
                        We  understand  the  challenges  businesses  face  when  it  comes  to  digital  marketing   that’s  why  our  integrated,  multi-channel  approach ensures <b style={{ color: colorRandom }} > maximum results </b> for your investment
                    </p>
                    <div className="locations d-flex justify-content-center">
                        
                        <div className="locationsContainer text-white p-5 row d-flex justify-content-center" style={{ background: colorRandom}}>
                            <div className="thumbContainer col-lg-4 col-md-4 col-sm-6">
                                <img className='thumblWorld' src={logo_planeta1} style={{'object-fit': 'cover'}} alt='---'/>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="secciones">
                        <br />
                        <div style={{ color: colorRandom }} className='h1' > Our <b > Company </b> </div>
                        <div className="linea" style={{ background: colorRandom }} ></div>
                        <br />
                        <p>
                            We are data nerds, marketers, designers, & developers who  partner  up  with  companies  to accelerate  their  growth with <b style={{ color: colorRandom }} > strong data-driven strategies. </b>
                        </p>
                        <p className='p-0 m-0'>
                            Our <b style={{ color: colorRandom }} > unique emphasis </b> on business intelligence, market
                            research, and data-driven strategies powered by a
                            business  mindset,  dramatically  increases  brand  value,
                            creates  effective  marketing  campaigns  and  improves
                            sucess rate.
                        </p>
                        <br />
                        <div className='text-center text-secondary'>
                            We’re not just another marketing agency.
                        </div>
                        <div className='text-center h1'>WE’RE A <b style={{ color: colorRandom }} > STRATEGIC PARTNER </b> </div>
                        <div className='text-center h1'>THAT GETS RESULTS.</div>
                        <br />
                        <br />
                        <br />
                        <div  style={{ color: colorRandom }} className='h1'> Our <b> Approach </b> </div>
                        <div className="linea" style={{ background: colorRandom }} ></div>
                        <br />
                        <p>With the power of data, we design memorable campaigns that are strategically grounded, enduring, and culturally relevant.
                        </p>
                        <br />
                        <p>
                            Our experts oversee the development of insights, ideas, and strategies that lead to successful plans and results.
                        </p>
                        <br />
                        <ul className='fuenteBold' style={{ color: colorRandom }} >
                            <li>DATA ANALYTICS</li>
                            <li>MARKET RESEARCH</li>
                            <li>MULTICULTURAL MARKETING</li>
                            <li>CREATIVITY & INNOVATION</li>
                        </ul>

                        <br />
                        <div style={{ color: colorRandom }} className='h1' > Our <b> Process </b> </div>
                        <div className="linea" style={{ background: colorRandom }} ></div>
                        <ol className='ourProcess'>
                            <li style={{ color: colorRandom }}><b>FIRST STEP</b>  Opportunities identification </li>
                            <li style={{ color: colorRandom }}><b>SECOND STEP</b> Data analysis </li>
                            <li style={{ color: colorRandom }}><b>THIRD STEP</b>  Strategy definition</li>
                            <li style={{ color: colorRandom }}><b>FOURTH STEP</b>  Development</li>
                            <li style={{ color: colorRandom }}><b>FIFTH STEP</b>  Plan execution</li>
                        </ol>

                        <br />
                        <div  style={{ color: colorRandom }} className='h1' > Our <b> Promise </b> </div>
                        <div className="linea" style={{ background: colorRandom }} ></div>
                        <br />
                        <p>
                            With  a  big <b style={{ color: colorRandom }} > passion  for  business  growth </b>  and  development, we will always aim to create marketing plans that adjust  to  your  goals  with  a  data-driven  strategy  that aligns  with  the <b style={{ color: colorRandom }} >  established  objectives </b> and  key  results.
                        </p>
                        <p>
                            Your business will get open discussions, workshops, and
                            regular  meetings  to  make  sure  we  are  reaching  those
                            goals.
                        </p>
                        <p>
                            The Digital Haus acts as an extension of your company,
                            expanding  your  brand,  guiding  your  strategy,  and
                            delivering targeted results.
                        </p>
                    </div>
                </div>
                <div style={{ background: colorRandom }}>
                    <FooterComponent />
                </div>
            </>
        );
    }
};

export default AboutComponent;
