import React from "react";
import { CardsArray } from "../variables";
import { useState, useEffect } from "react";
import "../Styles/workStyles.css"
import { Box,Grid } from '@material-ui/core'
import { Link } from "react-router-dom";
import { Colors } from "../variables";
import "../Styles/fontsStyles.css"
import HeaderComponent from "../components/header";

function shuffleArray(array) {
  const newArray = [...array]; // Copia el arreglo original para no modificarlo directamente
  let currentIndex = newArray.length;

  // Mientras queden elementos sin mezclar
  while (currentIndex !== 0) {
    // Obtén un elemento aleatorio del arreglo sin mezclar
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // Intercambia el elemento actual con el elemento aleatorio
    [newArray[currentIndex], newArray[randomIndex]] = [
      newArray[randomIndex],
      newArray[currentIndex],
    ];
  }

  return newArray;
}

function WorkPage(props) {
  let homeFlag = props?.homeFlag;
  const [cards, setCards] = useState(CardsArray);
  
  useEffect(() => {
    setCards(shuffleArray(CardsArray));
  }, []);
  
    return(
      <section style={{backgroundColor: `${props.colorRandom}`}}>
        <HeaderComponent  homeFlag={homeFlag} />
        
        <div className="App">
      <Grid container spacing={0}>
      {cards.map((card) => (
          <Grid item xs={6} sm={4} key={card.id} style={{paddingLeft:'2px', paddingTop:'2px', paddingBottom:'2px',paddingRight:'2px'}}>
            <Link to={`/work/${card.link}`}>
              <div className="image-container">
                <img src={card.image} alt={card.alt} className="image" />
                
                  <div className="overlay" style={{backgroundColor: props.colorTextRandom,borderTop:`2px solid ${props.colorRandom}`}}>
                  <div class="carousel-container">
                    <div class="carousel-text">
                    {card.carouselText.map((textC) => (
                        
                          <span style={{ color: props.colorRandom, fontFamily: 'Futura Oblique' }}>{textC}</span>
                      
                    ))}   
                    </div>
                    </div>
                  </div>
                
              </div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
    </section>
    )
        
}

export default WorkPage