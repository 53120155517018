import React, { useState} from "react";
import { Box,Grid } from '@material-ui/core'
import "../Styles/fontsStyles.css"
import "../Styles/OneWorkStyle.css"
import HeaderComponent from "../components/header";
import FooterComponent from '../components/footer';
import Accordion from 'react-bootstrap/Accordion';


function OneWorkPage(props){
    let workFlag = props?.workFlag;
    let resultado = props.resultado
    

    return(
        <section id="secOne">
            <div style={{backgroundColor:`${props.colorRandom}`}}>
                <HeaderComponent workFlag={workFlag} />
            </div>
            <div id="bod" style={{paddingTop: '1em'}}>
                <div>
                    <div style={{paddingBottom:'1em', borderBottom: '2px solid #3C1252'}}>
                        <h1 style={{ fontFamily: 'Futura ExtraBold', color:"#3C1252" }}>
                            <span style={{ fontFamily: 'Futura Regular', color:"#3C1252" }}>{resultado.text1}</span>
                            {resultado.text2}
                        </h1>
                    </div>
                    
                    <div>
                        <Grid container style={{paddingTop:'1em'}}>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Box>
                                    <h5 style={{fontFamily:'Futura Oblique'}}>Client</h5>
                                    <p style={{fontFamily:'Futura Regular'}}>{resultado.tile}</p>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Box>
                                    <h5 style={{fontFamily:'Futura Oblique'}}>Industry</h5>
                                    {resultado.textIndustry.map((item) =>(
                                        <p style={{fontFamily:'Futura Regular'}}>{item}</p>
                                    ))}
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Box>
                                    <h5 style={{fontFamily:'Futura Oblique'}}>Services</h5>
                                    {resultado.textServices.map((item) =>(
                                        <p style={{fontFamily:'Futura Regular'}}>{item}</p>
                                    ))}
                                    
                                </Box>
                            </Grid>
                        </Grid>
                        
                    </div>
                    <div>
                        <p style={{fontSize:'20px',paddingTop:'2em', fontFamily:'Futura Regular', textAlign: 'justify'}}>{resultado.copy1}</p>
                    </div>
                </div>

                {resultado.video1 ? (
                    <video style={{width: '100%', height:'20%'}} autoPlay muted loop>
                        <source src={resultado.video1} />
                    </video>
                ) : null}
                
                
                <Grid container justifyContent="center">
                {resultado.images.slice(0,3).map((item, index) => (
                    <Grid  item xs={12} sm={12} md={6} lg={4} >
                        <Box >
                            <img src={item} id="imMosaic" style={{ objectFit: 'cover', padding: '1em' }}/>
                        </Box>
                    </Grid>
                ))}
                </Grid>
                
                
                <div>
                    <p style={{fontSize:'20px',paddingTop:'2em', fontFamily:'Futura Regular',  textAlign: 'justify'}}>
                        {resultado.copy2}
                    </p>
                </div>
                {resultado.video2 ? (
                    <video style={{width: '100%', height:'20%'}} autoPlay muted loop>
                        <source src={resultado.video2} />
                    </video>
                ) : null}
                <Grid container justifyContent="center">
                {resultado.images.slice(3,6).map((item, index) => (
                    <Grid  item xs={12} sm={12} md={6} lg={4} >
                        <Box >
                            <img src={item} id="imMosaic" style={{ objectFit: 'cover',padding: '0.5em'}}/>
                        </Box>
                    </Grid>
                ))}
                </Grid>

                
                    
                    <Accordion defaultActiveKey="0" style={{paddingTop: '1em'}} className="custom-accordion">
                        <Accordion.Item eventKey="0" >
                            <Accordion.Header className="results-header">
                                <span style={{ fontSize: '30px', fontFamily: 'Futura Regular', color: '#3C1252' }}>Results</span>
                            </Accordion.Header>
                            <Accordion.Body style={{fontFamily:'Futura Regular'}}>
                                {resultado.results}
                            </Accordion.Body>
                            </Accordion.Item>
                    </Accordion>
                    
                    
                
                <div>
                    <p style={{fontSize:'20px',paddingTop:'2em', fontFamily:'Futura Regular',  textAlign: 'justify'}}>
                        {resultado.copy3}
                    </p>
                </div>
                <Grid container justifyContent="center">
                {resultado.images.slice(6,9).map((item, index) => (
                    <Grid  item xs={12} sm={12} md={6} lg={4} >
                        <Box >
                            <img src={item} id="imMosaic" style={{ objectFit: 'cover', padding: '1em' }}/>
                        </Box>
                    </Grid>
                ))}
                </Grid>
            </div>
            <div style={{ background: props.colorRandom}}>
                    <FooterComponent/>
            </div>
        </section>
    )
}

export default OneWorkPage