import React, { Component } from 'react';
import { NavLink } from 'react-router-dom/dist';
/** Estilos componente */

class LogoHeaderComponent extends Component {
    render() {
        let colorTextRandom = this.props?.colorTextRandom ?? "#ffff";
        return (
            <>
                <NavLink to="/" className="text-decoration-none">
                    <svg id="Capa_1" x="0px" y="0px" viewBox="0 0 800 800"
                        style={{ "enableBackground": "new 0 0 800 800" }}>
                        <g>
                            <g>
                                <path fill={colorTextRandom} d="M111.03,166.97v114.82H96.77V166.97H65.86v-13.25h76.42v13.25H111.03z" />
                                <path fill={colorTextRandom} d="M211.09,205.7h65.58v-51.98h14.26v128.06h-14.26v-62.84h-65.58v62.84h-14.26V153.72h14.26V205.7z" />
                                <path fill={colorTextRandom} d="M343.63,281.79V153.72h66.41v13.25H357.9v38.04h50.61v13.25H357.9v50.28h52.14v13.25H343.63z" />
                            </g>
                            <g>
                                <path fill={colorTextRandom} d="M93.41,458.21V337.95h22.64c9.99,0,18.65,1.01,25.99,3.04s14.31,5.84,20.89,11.49
			c13.3,11.49,19.94,26.73,19.94,45.77c0,18.18-6.54,33.12-19.6,44.82c-6.7,5.96-13.71,9.97-20.99,12.03
			c-7.28,2.08-16.12,3.11-26.56,3.11H93.41z M106.8,445.77h9.71c8.2,0,15.28-0.88,21.3-2.63c6.02-1.75,11.56-5.02,16.67-9.81
			c9.99-9.34,14.99-21.1,14.99-35.24c0-14.89-5.2-26.91-15.63-36.06c-5.1-4.58-10.59-7.65-16.51-9.25
			c-5.9-1.6-12.83-2.39-20.81-2.39h-9.71V445.77z"/>
                                <path fill={colorTextRandom} d="M224.26,337.95v120.26h-13.39V337.95H224.26z" />
                                <path fill={colorTextRandom} d="M324.12,397.76h50.07c0.21,9.15-0.53,17.02-2.24,23.62c-1.69,6.58-5.1,12.87-10.2,18.81
			c-5.41,6.39-12.09,11.33-20.01,14.83c-7.92,3.5-16.08,5.26-24.47,5.26c-16.92,0-31.46-6.07-43.63-18.24
			c-12.17-12.19-18.26-26.73-18.26-43.63c0-17.23,6.11-31.95,18.34-44.17s27-18.34,44.35-18.34c20.62,0,37.57,8.66,50.87,25.99
			l-9.73,9.09c-10.75-15.09-24.51-22.64-41.31-22.64c-13.61,0-25.19,4.95-34.75,14.84c-9.58,9.87-14.37,21.69-14.37,35.39
			c0,13.08,4.69,24.41,14.04,33.97c4.58,4.58,9.89,8.27,15.94,11.08c6.07,2.82,12.13,4.24,18.18,4.24c10.96,0,20.85-3.6,29.67-10.77
			c8.82-7.18,13.24-16.14,13.24-26.89h-35.72V397.76z"/>
                                <path fill={colorTextRandom} d="M423.43,337.95v120.26h-13.39V337.95H423.43z" />
                                <path fill={colorTextRandom} d="M498.55,350.39v107.82h-13.39V350.39h-29.03v-12.44h71.76v12.44H498.55z" />
                                <path fill={colorTextRandom} d="M608.43,424.08h-51.36l-14.5,34.13h-14.68l55.5-126l54.06,126h-14.84L608.43,424.08z M603.17,411.64
			l-20.09-48.17l-20.58,48.17H603.17z"/>
                                <path fill={colorTextRandom} d="M681.91,337.95v107.82h33.17v12.44h-46.57V337.95H681.91z" />
                            </g>
                            <g>
                                <path fill={colorTextRandom} d="M268.77,572.76h61.58v-48.81h13.39v120.26h-13.39V585.2h-61.58v59.01h-13.39V523.96h13.39V572.76z" />
                                <path fill={colorTextRandom} d="M460.34,610.09h-51.36l-14.5,34.13H379.8l55.5-126l54.06,126h-14.83L460.34,610.09z M455.08,597.64
			l-20.09-48.17l-20.58,48.17H455.08z"/>
                                <path fill={colorTextRandom} d="M538.87,523.96v71.61c0,4.58,0.04,7.83,0.08,9.73c0.06,1.91,0.51,4.65,1.36,8.22s2.12,6.52,3.84,8.86
			c2.55,3.5,6.07,6.29,10.59,8.37c4.54,2.06,9.07,3.11,13.65,3.11c4.36,0,8.68-0.97,13-2.88c4.3-1.91,7.83-4.52,10.59-7.81
			c1.81-2.24,3.19-4.98,4.15-8.22c0.95-3.25,1.5-5.96,1.6-8.14c0.12-2.18,0.16-5.3,0.16-9.33c0-0.86,0-1.5,0-1.93v-71.61h13.41
			v75.28c0,7.65-0.68,13.98-2.01,18.98c-1.32,4.98-4.17,9.77-8.53,14.35c-8.51,9.15-19.29,13.71-32.38,13.71
			c-12.34,0-22.7-4.09-31.11-12.27c-4.77-4.58-7.94-9.52-9.48-14.84c-1.54-5.31-2.32-11.97-2.32-19.94v-75.28H538.87z"/>
                                <path fill={colorTextRandom} d="M729.84,540.54l-10.69,6.85c-5-8.7-11.9-13.06-20.73-13.06c-5.41,0-10.1,1.75-14.04,5.26
			c-3.93,3.5-5.9,7.98-5.9,13.39c0,2.45,0.55,4.69,1.6,6.7c1.07,2.02,2.71,3.82,4.95,5.35c2.24,1.54,4.13,2.73,5.67,3.58
			c1.54,0.86,3.74,1.91,6.62,3.19l7.98,3.5c4.56,2.02,8.33,3.91,11.31,5.67c2.98,1.75,5.94,3.93,8.86,6.54
			c2.92,2.61,5.1,5.7,6.54,9.33c1.42,3.62,2.14,7.71,2.14,12.28c0,10.42-3.66,19.22-11,26.4c-7.34,7.16-16.28,10.75-26.79,10.75
			c-9.58,0-17.74-2.84-24.49-8.53c-6.76-5.68-11.08-13.2-12.99-22.56l13.06-3.66c0.86,6.27,3.52,11.56,7.98,15.87
			c4.46,4.3,9.83,6.46,16.1,6.46c6.6,0,12.36-2.26,17.31-6.79c4.95-4.52,7.42-10.07,7.42-16.67c0-2.65-0.45-5.12-1.34-7.42
			c-0.92-2.28-1.93-4.11-3.04-5.49c-1.11-1.38-2.9-2.88-5.35-4.48c-2.43-1.6-4.36-2.73-5.72-3.43c-1.38-0.68-3.62-1.71-6.72-3.11
			l-7.32-3.17c-8.51-3.84-14.99-7.98-19.47-12.44c-4.46-4.48-6.7-10.59-6.7-18.34c0-9.05,3.29-16.41,9.89-22.1
			c6.6-5.68,14.52-8.53,23.77-8.53c6.7,0,12.75,1.62,18.18,4.87C722.35,529.99,726.65,534.59,729.84,540.54z"/>
                            </g>
                        </g>
                    </svg>
                </NavLink>

            </>
        );
    }
};

export default LogoHeaderComponent;