import { BrowserRouter, Route, Routes } from 'react-router-dom/dist';
import { HashRouter } from "react-router-dom";
import './App.css';
/** Componentes */
import HomeComponent from './components/home';
import CompanyComponent from './components/company';
import AboutComponent from './components/about';
import ServicesComponent from './components/services';
import PricingComponent from './components/pricing';
import BrandsComponent from './components/brands';
import ContactComponent from './components/contact';
import WorkPage from './pages/WorkPage';
import OneWorkPage from './pages/OneWorkPage';
import NotFound from './components/notFound';
import { CardsArray } from "./variables";
import { useNavigate, useParams} from 'react-router-dom';
/** Bootstrap */
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
/** Font awesome */
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  let homeFlag = true;
  let workFlag = true;
  let companyFlag= true;
  let colors = ['#3C1252', '#6F4163', '#9478D3', '#BFAFD5', '#E4B22F']
  let colorsText = ['#3C1252', '#9478D3', '#BFAFD5', '#E4B22F']
  let colorRandom = colors[Math.floor(Math.random() * colors.length)];
  let colorTextRandom = colorsText[Math.floor(Math.random() * colorsText.length)];
  while (colorTextRandom === colorRandom || (colorRandom === '#E4B22F' && colorTextRandom === '#BFAFD5') || (colorTextRandom === '#E4B22F' && colorRandom === '#BFAFD5')) {
      colorTextRandom = colorsText[Math.floor(Math.random() * colorsText.length)];
  }
  function GetWorkPageRoute() {
    const navigate = useNavigate();
    const { name } = useParams();
    const resultado = CardsArray.find(card => card.link === name);
  
    if (!resultado) {
      navigate('/*');
      return null;
    }
  
    return <OneWorkPage workFlag={workFlag} colorRandom={colorRandom} colorTextRandom={colorTextRandom} resultado={resultado}/>;
  }
  return (
    <div className="App">
        <HashRouter>
          <Routes>
            <Route path="/work" element={<WorkPage colorRandom={colorRandom} colorTextRandom={colorTextRandom} />} />
            <Route path="/work/:name" element ={<GetWorkPageRoute />}/>
            <Route path="/" element={<HomeComponent colorRandom={colorRandom} colorTextRandom={colorTextRandom}/>} />
            <Route path="/company" element={<CompanyComponent colorRandom={colorRandom} colorTextRandom={colorTextRandom} />} />
            <Route path="/about" element={<AboutComponent colorRandom={colorRandom} colorTextRandom={colorTextRandom} companyFlag={companyFlag}/>} />
            <Route path="/services" element={<ServicesComponent colorRandom={colorRandom} colorTextRandom={colorTextRandom} companyFlag={companyFlag}/>} />
            <Route path="/pricing" element={<PricingComponent colorRandom={colorRandom} colorTextRandom={colorTextRandom} companyFlag={companyFlag}/>} />
            <Route path="/brands" element={<BrandsComponent colorRandom={colorRandom} colorTextRandom={colorTextRandom} companyFlag={companyFlag}/>} />
            <Route path="/contact" element={<ContactComponent colorRandom={colorRandom} colorTextRandom={colorTextRandom} companyFlag={companyFlag}/>} />
            <Route path="/*"  element={<NotFound colorRandom={colorRandom} colorTextRandom={colorTextRandom} />} />
          </Routes>
        </HashRouter>
    </div>
  );
}





export default App;