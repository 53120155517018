import React, { Component, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom/dist';
import HeaderComponent from './header';
/** Estilos componente */
import '../Styles/companyStyles.css'


class CompanyComponent extends Component {
    render() {
        let colorRandom = this.props.colorRandom;
        let colorTextRandom = this.props.colorTextRandom;
        let homeFlag = this.props.homeFlag
        let colorBox = { 
            border: '5px solid ' + colorTextRandom,
            background : colorRandom,
            width : '100%',
            height : '20vh'  
        };
        return (
            <>
                <div style={{ background: colorRandom, color: colorTextRandom }}>
                    <HeaderComponent colorTextRandom={colorTextRandom} homeFlag={homeFlag} />
                </div>
                <NavLink to="/about" className="text-decoration-none text-reset">

                    <div style={colorBox} className="nav-link d-flex justify-content-between align-items-center" >
                        <p style={{color: colorTextRandom}}>ABOUT</p>
                        <i className="fa-solid fa-caret-right" style={{ color: colorTextRandom }}></i>
                    </div>
                </NavLink>
                <NavLink to="/services" className="text-decoration-none text-reset">

                    <div style={colorBox} className="nav-link d-flex justify-content-between align-items-center" >
                        <p style={{color: colorTextRandom}}>SERVICES</p>
                        <i className="fa-solid fa-caret-right" style={{ color: colorTextRandom }}></i>
                    </div>
                </NavLink>
                <NavLink to="/pricing" className="text-decoration-none text-reset">

                    <div style={colorBox} className="nav-link d-flex justify-content-between align-items-center" >
                        <p style={{color: colorTextRandom}}>PRICING</p>
                        <i className="fa-solid fa-caret-right" style={{ color: colorTextRandom }}></i>
                    </div>
                </NavLink>
                <NavLink to="/brands" className="text-decoration-none text-reset">

                    <div style={colorBox} className="nav-link d-flex justify-content-between align-items-center" >
                        <p style={{color: colorTextRandom}}>BRANDS</p>
                        <i className="fa-solid fa-caret-right" style={{ color: colorTextRandom }}></i>
                    </div>
                </NavLink>
                <NavLink to="/contact" className="text-decoration-none text-reset">

                    <div style={colorBox} className="nav-link text-decoration-none text-reset d-flex justify-content-between align-items-center" >
                        <p style={{color: colorTextRandom}}>CONTACT</p>
                        <i className="fa-solid fa-caret-right" style={{ color: colorTextRandom }}></i>
                    </div>
                </NavLink>
            </>
        );
    }
};

export default CompanyComponent;
