import React, { Component } from 'react';
import FooterComponent from './footer';
import HeaderComponent from './header';
/** Estilos componente */
import '../Styles/servicesStyles.css'


class ServicesComponent extends Component {
    render() {
        let colorRandom = this.props.colorRandom;
        let companyFlag = this.props?.companyFlag;
        let servicios = [
            {
                title :'Marketing',
                label : 'Analyze, identify & manage',
                listado : ['Brand Communication','Marketing Personas','Digital Campaigns','Content Creation','Lead acquisition & Nurturing','Digital Media Strategy & Management','CRM Management'
                ]
            },
            {
                title : 'Design',
                label : 'Creation of meaningful & relevant experiences',
                listado : ['Graphic Design & Animation','Web & App Design',' Social Media Content','Data-Informed UX Design','UI & Interaction Design','Brand Identity'
                ]
            },
            {
                title : 'strategy',
                label : 'Research & positioning',
                listado : ['Market & Competitive Research',' Marketing Strategy','CRM & Sales Funnel Strategy','Content Creation','Brand Positioning & Messaging','Data-driven Insights & Opportunities','Process Automation'
                ]
            },
            {
                title : 'development',
                label : 'Technological solutions',
                listado : ['eCommerce','Platform & Data Migration','Website Maintenance & Hosting','Content Creation','CRM and other middleware','Data Collection UX Consulting'
                ]
            },
        ]
        let beneficios = ['Market research','Development of a custom marketing strategy', 'Oversee the implementation of your marketing plan and suggested tactics', 'Management of your internal and external marketing team', 'Provide detailed reporting on the progress of marketing initiatives']
        let resultados = ['A tailored marketing strategy that aligns with your business goals and improves bran visibility','Improved lead generation and increased sales revenue','Enhanced customer engagement and loyalty through effective marketing campaigns','Informed decision making through comprehensive marketing analytics and reporting']
        return (
            <>
                <div style={{ background: colorRandom, color:'#ffff' }}>
                    <HeaderComponent companyFlag={companyFlag}/>
                </div>
                <div className="containerServices container">
                    <br /><br />
                    <div className='h1' style={{ color: colorRandom }}>FRACTIONAL CMO</div>
                    <div className="linea" style={{ background: colorRandom }} ></div>
                    <div className=' fuenteBold h2' style={{ color: colorRandom }}>Marketing leadership y expertise</div>
                    <br />
                    <p>Get executive experience without the full-time cost.</p>
                    <p>We <b style={{ color: colorRandom }}>bridge the gap</b> between administrative, sales and marketing departments.</p>
                    <br />
                    <ul>
                        {beneficios.map((beneficio) =>
                            <>
                                <li>- {beneficio} </li>
                            </>
                        )}
                    </ul>
                    <br />
                    <p>Results <b style={{ color: colorRandom }}>you can expect</b> are : </p>
                    <ul>
                        {resultados.map((resultado) =>
                            <>
                                <li>- {resultado} </li>
                            </>
                        )}
                    </ul>
                    <br />
                    <ul>
                    {/* Servicios */}
                    {servicios.map((item) => 
                        (<>
                            <div className='h1' style={{ color: colorRandom }}> {item.title.toUpperCase()} </div>
                            <div className="linea" style={{ background: colorRandom }} ></div>
                            <h2 style={{ color: colorRandom }}>{item.label}</h2>
                            <br />
                            {item.listado.map((serv) => 
                                (<>
                                    <li> {serv} </li>
                                </>)
                            )}
                            <br /><br />
                        </>)
                    
                    )}
                    </ul>
                    <br /><br />
                </div>
                <div style={{ background: colorRandom }}>
                    <FooterComponent/>
                </div>
            </>
        );
    }
};

export default ServicesComponent;
